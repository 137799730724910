/* eslint-disable valid-typeof */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable object-shorthand */
/* eslint-disable camelcase */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/order */
/* eslint-disable no-new */
/* eslint-disable global-require */
import React, { useEffect, useState, useCallback, useRef } from 'react'
import ptLocale from '@fullcalendar/core/locales/pt-br'
import { useDispatch, useSelector } from 'react-redux'
import {
  withStyles,
  Grid,
  Paper,
  FormControlLabel,
  Checkbox,
  Slide,
  TextField,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  Typography,
  Switch,
  Box,
  IconButton,
  MenuItem,
} from '@material-ui/core'
import moment from 'moment-timezone'
import { useConfirm } from 'material-ui-confirm'
import queryString from 'query-string'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import Backdrop from '../../components/Backdrop'
import styles from '../../../resources/theme/global'
import {
  findAttendantes,
  getServicesUnbooked,
  getServiceUnbookedById,
  updateServiceItem,
  getServicesBooked,
  toogleOpenNextDay,
  findTechnicalAbsence,
  technicalAbsence,
  deleteTechnicalAbsence,
} from '../CalendarActions'
import { convertDateToUTC, renderRoute } from '../../../util/utils'

import Modal from './modal'
import Chips from './chips'

import { SNACKBAR } from '../../main/MainActions'

import '../../../resources/less/fullCalendar.less'
import DetachedForm from './DetachedForm'
import Reorder from './Reorder'
import classNames from 'classnames'
import { addDays, addMinutes, differenceInHours, format } from 'date-fns'
import consts from '../../../util/consts'

import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import colors from '../../../assets/colors'
import {
  mdiChevronLeft,
  mdiChevronRight,
  mdiMapMarkerPlus,
  mdiMessageQuestion,
} from '@mdi/js'
import Icon from '@mdi/react'
import Header from './Header'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import StyledCheckBox from '../../../components/hooks/BaseCheckBox'
import { ptBR } from 'date-fns/locale'
import ModalDay from './ModalDay'
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min'
import { object } from 'prop-types'

function Calendar(props) {
  const { classes, match } = props
  const calendarComponentRef = useRef()
  const eventsRef = useRef()
  const dispatch = useDispatch()
  const confirm = useConfirm()
  const attendants = useSelector(state => state.calendar.attendants)
  const technicalAbsences = useSelector(
    state => state.calendar.technicalAbsences
  )
  const [modalOpen, setModalOpen] = useState(false)
  const [backdrop, setBackdrop] = useState(false)
  const [currentAttendant, setCurrentattendant] = useState(null)
  const [currentEvent, setCurrentEvent] = useState(null)
  const [attendant, setAttendant] = useState('')
  const [unbooked, setUnbooked] = useState([])
  const [bookedEvents, setBookedEvents] = useState([])
  const [checkeds, setCheckeds] = useState([])
  const [collapse, setCollapse] = useState(true)
  const [showCurrent, setShowCurrent] = useState(false)
  const [detachedOpen, setDetachedOpen] = useState(false)
  const [reorderOpen, setReorderOpen] = useState(false)
  const [expanded, setExpanded] = React.useState(true)
  const [nextDayCalendarOpen, setNextDayCalendarOpen] = useState(false)
  const [selectedEvents, setSelectedEvents] = useState([])
  const [startHourToBook, setStartHourToBook] = useState(null)
  const [droppedEvent, setDroppedEvent] = useState(null)
  const [attendantsResource, setAttendantesResource] = useState([])
  const permissions = useSelector(state => state.auth.permissions)
  let timeout = 0

  const [view, setView] = useState('timeGridDay')
  const [date, setDate] = useState(new Date())
  const [elementPosition, setElementPosition] = useState({
    x: '',
    y: '',
  })
  const [selectAllEvents, setSelectAllEvents] = useState(false)
  const [selectedTechnical, setSelectedTechnical] = useState(null)

  const [bookedFiltered, setBookedFiltered] = useState(bookedEvents)
  const [addEventsDate, setAddEventsDate] = useState(null)
  const [addedAbsencesEvents, setAddedAbsencesEvents] = useState(false)
  const [addEventsAttendance, setAddEventsAttendance] = useState('')

  const history = useHistory()
  const location = useLocation()

  if (typeof window !== 'undefined') {
    const { default: FullCalendar } = require('@fullcalendar/react')
    const { default: dayGridPlugin } = require('@fullcalendar/daygrid')
    const { default: timeGridPlugin } = require('@fullcalendar/timegrid')
    const {
      default: resourceTimeGridPlugin,
    } = require('@fullcalendar/resource-timegrid')
    const {
      default: interactionPlugin,
      Draggable,
    } = require('@fullcalendar/interaction')

    const filterAttendant = useCallback(id => {
      setAttendant(id)
      setSelectedEvents([])
    })

    const handleChangeCollapse = useCallback(() => {
      setCollapse(prev => !prev)
    })

    const handleShowCurrent = useCallback(() => {
      setShowCurrent(prev => !prev)
    })

    const handleDetachedClose = useCallback(() => {
      setDetachedOpen(false)
    }, [])

    const handleReopenClose = useCallback(() => {
      setReorderOpen(false)
    }, [])

    const handleChecked = event => {
      const id = event.target.name
      const find = checkeds.findIndex(item => item === parseInt(id, 10))
      if (find > -1) {
        setCheckeds(checkeds.filter(item => item !== parseInt(id, 10)))
      } else {
        setCheckeds([...checkeds, parseInt(id, 10)])
      }
    }

    const checkIfHasTechnicalAbsence = (technicalId, eventStart, eventEnd) => {
      const start = new Date(eventStart)
      const end = new Date(eventEnd)

      const absences = technicalAbsences?.filter(
        technicalAbsence => technicalAbsence?.technical_id == technicalId
      )

      let hasAbsenceInPeriod = false

      absences?.map(absence => {
        const absenceStart = new Date(absence?.date_start)
        const absenceEnd = new Date(absence?.date_end)

        if (
          (start >= absenceStart && start <= absenceEnd) ||
          (end >= absenceStart && end <= absenceEnd)
        ) {
          hasAbsenceInPeriod = true
        }
      })

      return hasAbsenceInPeriod
    }

    // const handleChangeFilter = async e => {
    //   const { value } = e.target
    //   if (timeout) clearTimeout(timeout)
    //   timeout = setTimeout(async () => {
    //     const serviceId = match.params.id
    //     setBackdrop(true)
    //     const filtered = await dispatch(getServicesUnbooked(serviceId, value))
    //     setBackdrop(false)

    //     const { data } = filtered
    //     setUnbooked(data)
    //   }, 500)
    // }

    useEffect(() => {
      const qs = queryString.parse(props.location.search)

      if (attendant) {
        setBookedFiltered(
          bookedEvents.filter(item => item.attendant?.id === attendant)
        )
      } else if (showCurrent) {
        setBookedFiltered(
          bookedEvents.filter(
            item => item.serviceId == qs.os || item.id == qs.ositem
          )
        )
      } else {
        setBookedFiltered(bookedEvents)
      }
    }, [bookedEvents, attendant, showCurrent])

    const initialLoad = async () => {
      const serviceId = match.params.id
      const resp = await dispatch(getServicesUnbooked(serviceId))
      const respBooked = await dispatch(getServicesBooked())
      if (resp?.code === 200) {
        const { data } = resp

        setUnbooked(data)
      }

      if (respBooked?.code === 200) {
        const { data, nextDayCalendarOpen } = respBooked
        const qs = queryString.parse(props.location.search)

        setNextDayCalendarOpen(nextDayCalendarOpen)

        setBookedEvents(data)

        if (qs.os || qs.ositem) {
          setShowCurrent(true)
          const calendarApi = calendarComponentRef.current.getApi()
          calendarApi.changeView('dayGridMonth')
        }
      }
    }

    useEffect(() => {
      dispatch(findAttendantes())
      dispatch(findTechnicalAbsence())

      initialLoad()
    }, [])

    useEffect(() => {
      window.Echo.channel(`Calendar`).listen(
        `CalendarEventHasUpdatedEvent`,
        () => {
          initialLoad()
        }
      )

      return () => {
        window.Echo.leaveChannel(`Calendar`)
      }
    }, [])

    const reloadBookedEvents = useCallback(async () => {
      const respBooked = await dispatch(getServicesBooked())

      if (respBooked?.code === 200) {
        const { data } = respBooked
        const qs = queryString.parse(props.location.search)

        setBookedEvents(data)

        if (qs.os || qs.ositem) {
          setShowCurrent(true)
          const calendarApi = calendarComponentRef.current.getApi()
          calendarApi.changeView('dayGridMonth')
        }
      }
    }, [])

    useEffect(() => {
      const draggableEl = eventsRef.current
      new Draggable(draggableEl, {
        itemSelector: '.fc-event',
        eventData(eventEl) {
          const title = eventEl.getAttribute('title')
          const id = eventEl.getAttribute('id')
          const duration = eventEl.getAttribute('duration')
          const serviceId = eventEl.getAttribute('serviceId')
          const schedulingInformation = eventEl.getAttribute(
            'schedulingInformation'
          )
          const unbookedService = eventEl.getAttribute('unbookedService')
            ? true
            : false
          const unbookedBgColor = eventEl.getAttribute('unbookedBgColor')
          const neighborhood = eventEl.getAttribute('neighborhood')
          const service = eventEl.getAttribute('service')
          const client = eventEl.getAttribute('client')
          const vendedor = eventEl.getAttribute('vendedor')
          const bar_code = eventEl.getAttribute('bar_code')

          return {
            title,
            id,
            duration,
            serviceId,
            schedulingInformation,
            unbookedService,
            unbookedBgColor,
            neighborhood,
            service,
            client,
            vendedor,
            bar_code,
            revert: true,
            create: false,
          }
        },
      })
    }, [collapse])

    const dispatchToast = message => {
      return dispatch({
        type: SNACKBAR.HARDFAIL,
        error: {
          message: message,
        },
      })
    }

    useEffect(() => {
      if (!startHourToBook) {
        return
      }

      const messageWithIcons = (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="p">
                Deseja marcar o compromisso à partir das
                <KeyboardTimePicker
                  clearable={false}
                  ampm={false}
                  InputProps={{
                    classes: {
                      input: 'MuiInputBase-inputOverride',
                    },
                  }}
                  invalidDateMessage="Horário inválido"
                  value={moment(startHourToBook, 'HH:mm').toDate()}
                  onChange={date =>
                    setStartHourToBook(moment(date).format('HH:mm'))
                  }
                  style={{ margin: '-10px 5px -10px 10px', maxWidth: '180px' }}
                />
                ?
              </Typography>
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      )

      confirm({
        description: messageWithIcons,
        title: 'Tem certeza?',
        confirmationText: 'Sim',
        cancellationText: 'Cancelar',
        confirmationButtonProps: {
          disabled: !moment(startHourToBook, 'HH:mm').isValid(),
          autoFocus: true,
        },
        dialogProps: {
          maxWidth: 'sm',
          fullWidth: true,
        },
      }).then(async () => {
        const { id } = droppedEvent.draggedEl
        let newCheckeds = [...checkeds]
        if (checkeds.indexOf(parseInt(id, 10)) === -1) {
          newCheckeds = [parseInt(id, 10), ...newCheckeds]
        }
        let initial = null
        let final = null
        const newEvents = []
        let hasTechnicalAbsence = false

        newCheckeds.forEach((ident, key) => {
          const unbookedFind = unbooked.find(
            item => item.id === parseInt(ident, 10)
          )
          if (key === 0) {
            initial = moment(droppedEvent.dateStr).set({
              hour: startHourToBook.split(':')[0],
              minute: startHourToBook.split(':')[1],
            })
            final = moment(droppedEvent.dateStr)
              .set({
                hour: startHourToBook.split(':')[0],
                minute: startHourToBook.split(':')[1],
              })
              .add(unbookedFind.final_time)
          } else {
            initial = final.clone()
            final = final.add(unbookedFind.final_time)
          }
          let newEvent = {}
          if (unbookedFind.service?.attendanceType === consts.NO_CLIENTE) {
            newEvent = {
              title: `
              Bairro: ${unbookedFind.client?.neighborhood} -
              Item: ${unbookedFind.service?.name} -
              Tempo: ${unbookedFind.final_time} -
              Cliente: ${unbookedFind.client?.name} -
              Etiqueta: ${unbookedFind.bar_code}`,
              title_json: {
                bairro: unbookedFind.client?.neighborhood,
                item: unbookedFind.service?.name,
                client: unbookedFind.client?.name,
                vendedor: unbookedFind.vendedor.name,
              },
              start: initial.format(),
              end: final.format(),
              serviceId: unbookedFind.serviceId,
              id: unbookedFind.id,
              service_id_laundry_client: unbookedFind.id_laundry_client,
            }
          } else {
            newEvent = {
              title: `
               Bairro: ${unbookedFind.client?.neighborhood} -
               Item: ${unbookedFind.service?.name} -
               Tempo: ${unbookedFind.final_time} - 
               Cliente: ${unbookedFind.client?.name} -
               Etiqueta: ${unbookedFind.bar_code}`,
              title_json: {
                bairro: unbookedFind.client?.neighborhood,
                item: unbookedFind.service?.name,
                client: unbookedFind.client?.name,
                vendedor: unbookedFind.vendedor.name,
              },
              start: moment(droppedEvent.dateStr)
                .set({
                  hour: startHourToBook.split(':')[0],
                  minute: startHourToBook.split(':')[1],
                })
                .format(),
              end: moment(droppedEvent.dateStr)
                .set({
                  hour: startHourToBook.split(':')[0],
                  minute: startHourToBook.split(':')[1],
                })
                .add(unbookedFind.final_time)
                .format(),
              serviceId: unbookedFind.serviceId,
              id: unbookedFind.id,
              service_id_laundry_client: unbookedFind.id_laundry_client,
            }
          }
          if (attendant) {
            const att = attendants.find(item => item.id === attendant)
            const newProps = {
              ...newEvent,
              attendant: att,
              backgroundColor: att.color,
            }
            newEvents.push(newProps)
          } else {
            newEvents.push(newEvent)
          }

          if (
            checkIfHasTechnicalAbsence(
              attendant,
              newEvent?.start,
              newEvent?.end
            )
          ) {
            hasTechnicalAbsence = true
          }
        })

        if (hasTechnicalAbsence) {
          return dispatchToast('Técnico ausente no período marcado')
        }

        setCheckeds([])
        setBackdrop(true)
        const result = newEvents.reduce((promise, nextEvent, index, array) => {
          const isLast = index + 1 === array.length
          return promise.then(() => {
            return dispatch(updateServiceItem(nextEvent.id, nextEvent, isLast))
          })
        }, Promise.resolve())
        result.then(() => {
          setBackdrop(false)
          setBookedEvents([...bookedEvents, ...newEvents])
        })

        setUnbooked(
          unbooked.filter(item => {
            return newCheckeds.indexOf(item.id) === -1
          })
        )

        // initialLoad()
      })
    }, [droppedEvent, startHourToBook])

    const onDrop = dropInfo => {
      if (!attendant) {
        dispatch({
          type: SNACKBAR.HARDFAIL,
          error: { message: 'Para agendar o evento selecione um técnico.' },
        })

        return
      }

      setStartHourToBook(moment(dropInfo.dateStr).format('HH:mm'))
      setDroppedEvent(dropInfo)
    }

    const handleSelectAllFromAttendant = attendantId => {
      const calendarApi = calendarComponentRef.current.getApi()
      const { start, end } = calendarApi.state.dateProfile.currentRange
      const startCalendarDate = convertDateToUTC(new Date(start))
      const endCalendarDate = convertDateToUTC(new Date(end))

      setSelectedEvents(
        bookedEvents
          .filter(item => {
            const startEventDate = new Date(item.start)
            const isBetween =
              startCalendarDate.getTime() <= startEventDate.getTime() &&
              endCalendarDate.getTime() >= startEventDate.getTime()

            return item.attendant?.id === attendantId && isBetween
          })
          .map(item => String(item.id))
      )
    }

    const handleeventDrop = eventDropInfo => {
      const booked = [...bookedEvents]
      const { id } = eventDropInfo.event
      const { copy } = eventDropInfo.event.extendedProps
      const finded = booked.findIndex(
        item => item.id === parseInt(id, 10) && item.copy === copy
      )

      booked[finded] = {
        ...booked[finded],
        start: moment(eventDropInfo.event.start)
          .tz('America/Sao_Paulo')
          .format(),
        end: moment(eventDropInfo.event.end).tz('America/Sao_Paulo').format(),
      }

      setBackdrop(true)
      const hasMoreEvents = selectedEvents.indexOf(id) === -1
      dispatch(
        updateServiceItem(booked[finded].id, booked[finded], hasMoreEvents)
      ).then(resp => {
        if (resp?.code === 200) {
          setBookedEvents(booked)
        }

        setBackdrop(false)
      })

      if (selectedEvents.indexOf(id) === -1) return
      const filteredNoCurrent = selectedEvents.filter(item => item !== id)

      if (filteredNoCurrent.length > 0) {
        const newStart = moment(eventDropInfo.event.start).tz(
          'America/Sao_Paulo'
        )
        const oldStart = moment(eventDropInfo.oldEvent.start).tz(
          'America/Sao_Paulo'
        )

        const diff = moment.duration(newStart.diff(oldStart)).asMinutes()

        filteredNoCurrent.forEach((filteredItem, key) => {
          const isLast = key + 1 === filteredNoCurrent.length

          const findIndex = booked.findIndex(
            item => item.id === parseInt(filteredItem, 10)
          )
          const currentEventStart = moment(booked[findIndex].start).tz(
            'America/Sao_Paulo'
          )
          const currentEventEnd = moment(booked[findIndex].end).tz(
            'America/Sao_Paulo'
          )
          const currentEventDiff = moment
            .duration(currentEventEnd.diff(currentEventStart))
            .asMinutes()

          booked[findIndex] = {
            ...booked[findIndex],
            start: currentEventStart.add(diff, 'minutes').format(),
            end: currentEventStart.add(currentEventDiff, 'minutes').format(),
          }

          dispatch(
            updateServiceItem(booked[findIndex].id, booked[findIndex], isLast)
          )
        })

        setBookedEvents(booked)
      }

      setSelectedEvents([])
    }

    const handleClose = useCallback(() => {
      setModalOpen(false)
    }, [])

    const handleEventClick = eventClickInfo => {
      const { id } = eventClickInfo.event
      const { copy } = eventClickInfo.event.extendedProps

      if (eventClickInfo?.jsEvent?.ctrlKey) {
        const index = selectedEvents.indexOf(id)

        if (index > -1) {
          setSelectedEvents(events => events.filter(item => item !== id))
        } else {
          setSelectedEvents(events => [...events, id])
        }
      } else {
        const event = bookedEvents.find(
          item => item.id === parseInt(id, 10) && item.copy === copy
        )
        setCurrentattendant(event.attendant)
        setCurrentEvent(event)
        setModalOpen(true)
      }
    }

    useEffect(() => {
      if (selectedEvents.length > 0) {
        dispatch({
          type: SNACKBAR.INFO,
          success: {
            message: `${selectedEvents.length} evento(s) selecionados`,
          },
        })
      }
    }, [selectedEvents])

    const handleCancel = useCallback(
      (id, copy) => {
        confirm({
          description: 'Deseja desmarcar o compromisso?',
          title: 'Tem certeza?',
          confirmationText: 'Sim',
          cancellationText: 'Cancelar',
          dialogProps: {
            fullWidth: true,
            classes: {},
          },
        }).then(async () => {
          setModalOpen(false)
          setBackdrop(true)
          const info = await dispatch(getServiceUnbookedById(id, copy))
          if (info?.code === 200) {
            const { data, renderBack } = info
            if (renderBack) setUnbooked([...unbooked, data])
            setBookedEvents(bookedEvents.filter(item => item.id !== id))
          }
          setBackdrop(false)
        })
      },
      [bookedEvents, unbooked]
    )

    const handleCancelTechnicalAbsence = useCallback(
      (id, copy) => {
        confirm({
          description: 'Deseja desmarcar a ausência do técnico?',
          title: 'Tem certeza?',
          confirmationText: 'Sim',
          cancellationText: 'Cancelar',
          dialogProps: {
            fullWidth: true,
            classes: {},
          },
        }).then(async () => {
          setModalOpen(false)
          const info = await dispatch(deleteTechnicalAbsence(id))
          if (info?.code == 200) {
            setBookedEvents(bookedEvents.filter(item => item.id !== id))
          }
        })
      },
      [technicalAbsences, bookedEvents]
    )

    const changeAttendant = (event, attendant) => {
      const booked = [...bookedEvents]

      const { copy } = event

      const finded = booked.findIndex(
        item => item.id === parseInt(event.id, 10) && item.copy === copy
      )

      booked[finded] = {
        ...booked[finded],
        start: moment(booked[finded].start).tz('America/Sao_Paulo').format(),
        end: moment(booked[finded].end).tz('America/Sao_Paulo').format(),
        attendant,
        backgroundColor: attendant.color,
      }

      setModalOpen(false)
      setBackdrop(true)
      const hasMoreEvents = selectedEvents.includes(String(event.id))
      dispatch(
        updateServiceItem(booked[finded].id, booked[finded], !hasMoreEvents)
      ).then(resp => {
        if (resp?.code === 200) {
          if (!selectedEvents.includes(String(event.id))) {
            setBookedEvents(booked)
            setAttendant(null)
            setBackdrop(false)
          }
        }
      })

      if (selectedEvents.includes(String(event.id))) {
        const result = selectedEvents.reduce(
          (promise, nextEvent, index, array) => {
            const isLast = index + 1 === array.length
            return promise.then(() => {
              const findIndex = booked.findIndex(
                item => item.id === parseInt(nextEvent, 10)
              )

              booked[findIndex] = {
                ...booked[findIndex],
                attendant,
                backgroundColor: attendant.color,
              }

              return dispatch(
                updateServiceItem(
                  booked[findIndex].id,
                  booked[findIndex],
                  isLast
                )
              )
            })
          },
          Promise.resolve()
        )

        result.then(() => {
          setBookedEvents(booked)
          setAttendant(null)
          setBackdrop(false)
        })

        setSelectedEvents([])
      }
    }

    const customEventRender = info => {
      const { id } = info.event
      const index = selectedEvents.indexOf(id)
      if (index > -1) {
        info.el.style.opacity = 0.4
      } else {
        info.el.style.opacity = 1
      }
    }

    const changeViewToDate = useCallback(date => {
      const momentDate = moment(date).format()

      const calendarApi = calendarComponentRef.current.getApi()
      calendarApi.changeView('timeGridDay', momentDate)
    }, [])

    const getBgColor = color => {
      if (color) return color
    }

    useEffect(() => {
      if (attendants) {
        setAttendantesResource([])

        attendants?.map(attendant => {
          setAttendantesResource(prevState => [
            ...prevState,
            {
              id: attendant?.id,
              title: attendant?.name,
              color: attendant?.color,
              eventTextColor: 'red',
            },
          ])
        })
      }
    }, [attendants])

    const filterStyles = filterStylesComponents()

    const handleNextDay = () => {
      const calendarApi = calendarComponentRef.current.getApi()
      calendarApi.prev()

      const nextDate = new Date(calendarApi.getDate())
      setDate(nextDate)
    }

    const handlePrevDay = () => {
      const calendarApi = calendarComponentRef.current.getApi()
      calendarApi.next()

      const prevDate = new Date(calendarApi.getDate())
      setDate(prevDate)
    }

    const handleChangeDate = date => {
      if (date?.start) {
        const currentDate = new Date(date?.start)

        setDate(currentDate)
      }
    }

    const handleSelectAllEvents = () => {
      if (!selectAllEvents) {
        setCheckeds([])

        unbooked?.map(event => {
          setCheckeds(prevState => [...prevState, event?.id])
        })
      } else {
        setCheckeds([])
      }

      setSelectAllEvents(!selectAllEvents)
    }

    const eventContent = info => {
      const { event } = info
      const { extendedProps, title } = event
      const {
        unbookedService,
        isTechnicalAbsence,
        unbookedBgColor,
        neighborhood,
        service,
        client,
        vendedor,
        bar_code,
      } = extendedProps

      return (
        <>
          {isTechnicalAbsence && (
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12} style={{ fontWeight: 700 }}>
                Ausente
              </Grid>
            </Grid>
          )}

          {unbookedService && (
            <Box
              style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                borderRadius: '10px',
                gap: '10px',
                border: '1px solid #F1F1F1',
              }}
            >
              <Box
                style={{
                  width: '8px',
                  borderRadius: '10px 0 0 10px',
                  backgroundColor: unbookedBgColor,
                }}
              />

              <Box
                style={{
                  flex: '1',
                  borderRadius: '10px',
                  color: '#646464',
                  fontSize: '12px',
                  padding: '6px 0',
                  lineHeight: '14px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '4px',
                }}
              >
                <div>{info.timeText}</div>
                <div>
                  {`Bairro: ${neighborhood}`}
                  <br />
                  {`Item: ${service}`}
                  <br />
                  {`Cliente: ${client}`}
                  <br />
                  {`Vendedor: ${vendedor}`}
                  <br />
                  {bar_code ? `Etiqueta: ${bar_code}` : ''}
                </div>
              </Box>
            </Box>
          )}

          {!isTechnicalAbsence && !unbookedService && (
            <Box
              style={{
                gap: '8px',
                display: 'flex',
                flexWrap: 'wrap',
                fontSize: '12px',
              }}
            >
              <div style={{ width: '100%' }}>{info.timeText}</div>
              <div>{title}</div>
            </Box>
          )}
        </>
      )
    }

    const convertHourToMinutes = hora => {
      const partes = hora.split(':')
      const horas = parseInt(partes[0], 10)
      const minutos = parseInt(partes[1], 10)
      return horas * 60 + minutos
    }

    const addMultipleEventsMessage = () => {
      const startDate = new Date(addEventsDate)
      const date = format(startDate, 'dd/MM/yyyy', { locale: ptBR })
      const time = format(startDate, 'HH:mm', { locale: ptBR })

      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="p">
              Deseja marcar o compromisso para o dia {date} a partir das {time}?
            </Typography>
          </Grid>
        </Grid>
      )
    }

    const handleScheduleServices = () => {
      const currentDate = new Date()

      if (!checkeds?.length) {
        return dispatchToast('Não há nenhum evento selecionado')
      }

      if (!addEventsAttendance?.id) {
        return dispatchToast('Não há nenhum técnico selecionado')
      }

      if (!addEventsDate) {
        return dispatchToast('Não há nenhuma data selecionada')
      }

      const dateStart = new Date(addEventsDate)

      if (dateStart < currentDate) {
        return dispatchToast('A data não pode ser menor que o dia de hoje')
      }

      confirm({
        description: addMultipleEventsMessage(),
        title: 'Tem certeza?',
        confirmationText: 'Sim',
        cancellationText: 'Cancelar',
        dialogProps: {
          maxWidth: 'sm',
          fullWidth: true,
        },
      }).then(async () => {
        let firstEventStart = addEventsDate
        let eventEnd = null

        const newEvents = []

        let hasTechnicalAbsence = false

        checkeds?.map((id, index) => {
          const event = unbooked.find(event => event?.id === id)

          if (event) {
            // se for o primeiro evento a data inicial será a data marcada no componente
            let eventStart = null

            if (index === 0) {
              eventStart = firstEventStart
            } else {
              const lastEventEnd = new Date(eventEnd)
              eventStart = format(lastEventEnd, "yyyy-MM-dd'T'HH:mm")
            }

            const minutes = convertHourToMinutes(event?.final_time)

            eventEnd = addMinutes(dateStart, minutes)
            eventEnd = format(eventEnd, "yyyy-MM-dd'T'HH:mm")

            const newEvent = {
              title: `
                        Bairro: ${event.client?.neighborhood} -
                        Item: ${event.service?.name} -
                        Tempo: ${event.final_time} -
                        Cliente: ${event.client?.name} -
                        Etiqueta: ${event.bar_code}`,
              title_json: {
                bairro: event.client?.neighborhood,
                item: event.service?.name,
                client: event.client?.name,
                vendedor: event.vendedor.name,
              },
              start: eventStart,
              end: eventEnd,
              serviceId: event.serviceId,
              id: event.id,
              service_id_laundry_client: event.id_laundry_client,
              attendant: addEventsAttendance,
              backgroundColor: addEventsAttendance?.color,
            }

            if (
              checkIfHasTechnicalAbsence(
                addEventsAttendance?.id,
                eventStart,
                eventEnd
              )
            ) {
              hasTechnicalAbsence = true
            }

            newEvents.push(newEvent)
          }
        })

        if (hasTechnicalAbsence) {
          return dispatchToast('Técnico ausente no período marcado')
        }

        setBackdrop(true)

        const result = newEvents.reduce((promise, nextEvent, index, array) => {
          const isLast = index + 1 === array.length
          return promise.then(() => {
            return dispatch(updateServiceItem(nextEvent.id, nextEvent, isLast))
          })
        }, Promise.resolve())
        result.then(() => {
          setBackdrop(false)
          setBookedEvents([...bookedEvents, ...newEvents])
        })
        setUnbooked(
          unbooked.filter(item => {
            return checkeds.indexOf(item.id) === -1
          })
        )

        setCheckeds([])
      })
    }

    const addAbsenceEvents = () => {
      let newEvents = []

      // if (!technicalAbsences.length) return

      technicalAbsences?.map(technicalAbsence => {
        const dateStart = new Date(technicalAbsence?.date_start)
        const dateEnd = new Date(technicalAbsence?.date_end)

        const absencesHours = differenceInHours(dateEnd, dateStart)

        const newEvent = {
          title: `Técnico ausente`,
          start: format(dateStart, "yyyy-MM-dd'T'HH:mm"),
          end: format(dateEnd, "yyyy-MM-dd'T'HH:mm"),
          id: technicalAbsence.id,
          service_id_laundry_client:
            technicalAbsence?.technical?.laundry_client_id,
          attendant: technicalAbsence?.technical,
          backgroundColor: `${technicalAbsence?.technical?.color}CC`,
          isTechnicalAbsence: true,
          absenceReason: technicalAbsence?.reason,
        }

        newEvents.push(newEvent)
      })

      setBookedEvents([...bookedEvents, ...newEvents])
      setAddedAbsencesEvents(true)
    }

    const handleDateNavigation = date => {
      const dateToGo = new Date(date)
      setView('timeGridDay')
      setDate(dateToGo)

      const calendarApi = calendarComponentRef.current.getApi()
      calendarApi?.gotoDate(dateToGo)
    }

    useEffect(() => {
      if (
        technicalAbsences.length &&
        bookedEvents.length &&
        addedAbsencesEvents == false
      ) {
        addAbsenceEvents()
      }
    }, [bookedEvents, technicalAbsences])

    useEffect(() => {
      handleClose()
    }, [view, date, collapse])

    useEffect(() => {
      const qs = queryString.parse(props.location.search)

      if (qs?.reload) {
        setAddedAbsencesEvents(false)
      }
    }, [location])

    return (
      <>
        <Backdrop open={backdrop} />
        {renderRoute(['create-calendar-detached'], permissions) && (
          <DetachedForm
            open={detachedOpen}
            attendants={attendants}
            handleClose={handleDetachedClose}
            reloadBookedEvents={reloadBookedEvents}
          />
        )}

        {renderRoute(['create-calendar-detached'], permissions) && (
          <Reorder
            open={reorderOpen}
            attendants={attendants}
            handleClose={handleReopenClose}
            reloadBookedEvents={reloadBookedEvents}
            changeViewToDate={changeViewToDate}
          />
        )}
        {/* {view == 'timeGridDay' ? (
          <>
      
          </>
        ) : (
          <>
            <Modal
              open={modalOpen}
              elementPosition={elementPosition}
              handleClose={handleClose}
              attendants={attendants}
              currentAttendant={currentAttendant}
              currentEvent={currentEvent}
              changeAttendant={changeAttendant}
              handleCancel={handleCancel}
            />
          </>
        )} */}
        <ModalDay
          open={modalOpen}
          handleClose={handleClose}
          attendants={attendants}
          currentAttendant={currentAttendant}
          currentEvent={currentEvent}
          changeAttendant={changeAttendant}
          handleCancel={handleCancel}
          handleCancelTechnicalAbsence={handleCancelTechnicalAbsence}
        />

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              style={{ fontSize: '20px' }}
              color={colors.primary}
            >
              Calendário
              <IconButton className={classes.questionBox}>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <a href="" target="_blank" rel="noreferrer">
                  <Icon
                    path={mdiMessageQuestion}
                    size={1}
                    color={colors.secondary}
                    style={{
                      position: 'absolute',
                      top: '-10px',
                      right: '-10px',
                    }}
                  />
                </a>
              </IconButton>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Header showServices={collapse} setShowServices={setCollapse} />
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              {collapse ? (
                <Grid item xs={3}>
                  <Slide
                    direction="right"
                    in={collapse}
                    timeout={350}
                    className={classes.calendarEventsContainer}
                  >
                    <Grid container spacing={2} alignContent="flex-start">
                      <Grid item xs={12}>
                        <Box className={classes.periodBox}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  className={classes.periodBoxItemTitle}
                                >
                                  Adicionar itens selecionado ao técnico:
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField
                                    select
                                    label="Selecione um Técnico"
                                    fullWidth
                                    value={addEventsAttendance}
                                    onChange={e => {
                                      setAddEventsAttendance(e.target.value)
                                    }}
                                  >
                                    <MenuItem value="">Selecione</MenuItem>
                                    {attendants?.map(attendance => (
                                      <MenuItem
                                        key={attendance?.id}
                                        value={attendance}
                                      >
                                        {attendance?.name}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid item xs={12}>
                                <Grid container spacing={1}>
                                  <Grid
                                    item
                                    xs={12}
                                    className={classes.periodBoxItemTitle}
                                  >
                                    Selecione um dia e horário:
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Grid
                                      container
                                      spacing={2}
                                      alignItems="flex-end"
                                      justifyContent="flex-start"
                                    >
                                      <Grid item xs={7}>
                                        <TextField
                                          type="datetime-local"
                                          InputLabelProps={{ shrink: true }}
                                          value={addEventsDate}
                                          onChange={e => {
                                            setAddEventsDate(e.target.value)
                                          }}
                                        />
                                      </Grid>

                                      <Grid
                                        item
                                        xs
                                        container
                                        justifyContent="flex-end"
                                      >
                                        <IconButton
                                          className={classes.btnAdicionar}
                                          onClick={handleScheduleServices}
                                        >
                                          <Icon
                                            path={mdiMapMarkerPlus}
                                            size={1}
                                          />
                                          Adicionar
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Paper
                              className={classes.paperEvents}
                              ref={eventsRef}
                              elevation={0}
                            >
                              <Grid container alignItems="center">
                                <StyledCheckBox
                                  onClick={handleSelectAllEvents}
                                  checked={selectAllEvents}
                                />
                                Selecionar todos os itens
                              </Grid>

                              {unbooked.map(event => {
                                const { id } = event

                                // const selectedAttendant = attendants?.find(
                                //   att => att?.id == attendant
                                // )

                                return (
                                  <Grid
                                    container
                                    spacing={1}
                                    key={event.id}
                                    className="fc-event"
                                    title={event.service?.name}
                                    textColor="#000"
                                    id={id}
                                    duration={event.final_time}
                                    attendant={event.attendant}
                                    client_name={
                                      event.client.name || 'Não definido'
                                    }
                                    schedulingInformation={
                                      event.schedulingInformation
                                    }
                                    unbookedService="true"
                                    unbookedBgColor={event.service?.color}
                                    neighborhood={event.client?.neighborhood}
                                    service={event.service?.name}
                                    client={event.client?.name}
                                    vendedor={event.vendedor?.name}
                                    bar_code={event.bar_code}
                                  >
                                    <Grid
                                      item
                                      className="fc-event-indicator"
                                      style={{
                                        backgroundColor: getBgColor(
                                          event.service?.color
                                        ),
                                      }}
                                    />

                                    <Grid
                                      item
                                      xs={2}
                                      className="fc-event-checkbox"
                                    >
                                      <StyledCheckBox
                                        checked={checkeds.indexOf(id) > -1}
                                        onChange={handleChecked}
                                        name={`${id}`}
                                      />
                                    </Grid>
                                    <Grid item xs>
                                      <div>
                                        {`Bairro: ${event.client?.neighborhood}`}
                                        <br />
                                        {`Item: ${event.service?.name}`} <br />
                                        {`Cliente: ${event.client?.name}`}
                                        <br />
                                        {event.vendedor?.name
                                          ? `Vendedor: ${event.vendedor?.name}`
                                          : ''}
                                        <br />
                                        {event.bar_code
                                          ? `Etiqueta: ${event.bar_code}`
                                          : ''}
                                      </div>
                                    </Grid>
                                  </Grid>
                                )
                              })}
                            </Paper>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Slide>
                </Grid>
              ) : (
                <div>
                  <span ref={eventsRef} />
                </div>
              )}

              <Grid item xs>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container justifyContent="space-between">
                      <Grid item xs>
                        <Box className={classes.calendarButtons}>
                          <IconButton
                            className={classes.calendarButton}
                            onClick={handleNextDay}
                          >
                            <Icon path={mdiChevronLeft} size={1} />
                          </IconButton>

                          <IconButton
                            className={classes.calendarButton}
                            onClick={handlePrevDay}
                          >
                            <Icon path={mdiChevronRight} size={1} />
                          </IconButton>
                        </Box>
                      </Grid>

                      <Grid item xs>
                        <Box className={classes.calendarHeaderTitle}>
                          {format(date, "iiii, dd 'de' MMMM 'de' yyyy", {
                            locale: ptBR,
                          })}
                        </Box>
                      </Grid>

                      <Grid item xs container justifyContent="flex-end">
                        <Box className={classes.periodButtons}>
                          <IconButton
                            className={[
                              classes.periodButton,
                              view == 'dayGridMonth' && classes.active,
                            ]}
                            onClick={() => setView('dayGridMonth')}
                          >
                            Mês
                          </IconButton>

                          <IconButton
                            className={[
                              classes.periodButton,
                              view == 'timeGridWeek' && classes.active,
                            ]}
                            onClick={() => setView('timeGridWeek')}
                          >
                            Semana
                          </IconButton>

                          <IconButton
                            className={[
                              classes.periodButton,
                              view == 'timeGridDay' && classes.active,
                            ]}
                            onClick={() => setView('timeGridDay')}
                          >
                            Dia
                          </IconButton>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Chips
                      {...props}
                      attendants={attendants}
                      filterAttendant={filterAttendant}
                      collapse={collapse}
                      handleChangeCollapse={handleChangeCollapse}
                      attendant={attendant}
                      showCurrent={showCurrent}
                      handleShowCurrent={handleShowCurrent}
                      showCollapse
                      handleSelectAllFromAttendant={
                        handleSelectAllFromAttendant
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <div className={classes.calendarBody}>
                      <FullCalendar
                        plugins={[
                          dayGridPlugin,
                          timeGridPlugin,
                          interactionPlugin,
                        ]}
                        headerToolbar={{
                          left: '',
                          center: '',
                          right: '',
                        }}
                        // dayHeaderFormat={{ weekday: 'short' }}
                        key={view}
                        dateClick={info => handleChangeDate(info)}
                        datesSet={info => handleChangeDate(info)}
                        initialView={view}
                        locale={ptLocale}
                        eventContent={eventContent}
                        editable
                        height="parent"
                        defaultDate={date}
                        date
                        droppable
                        ref={calendarComponentRef}
                        slotDuration="00:30:00"
                        slotLabelInterval="00:30:00"
                        slotMaxTime="24:00:00"
                        slotMinTime="00:00:00"
                        slotLabelFormat={{
                          hour: 'numeric',
                          minute: '2-digit',
                          omitZeroMinute: false,
                          meridiem: 'short',
                        }}
                        scrollTime="07:00:00"
                        navLinks
                        weekNumberCalculation="ISO"
                        eventDurationEditable={false}
                        selectMirror
                        dayMaxEvents
                        events={bookedFiltered}
                        drop={onDrop}
                        eventDrop={handleeventDrop}
                        eventClick={handleEventClick}
                        allDaySlot={false}
                        navLinkDayClick={handleDateNavigation}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }
  return <div />
}

export default withStyles(styles)(Calendar)

import axios from 'axios'

import config from '../../config'

export const fetchViaCep = async cep => {
  const resp = await axios
    .get(`https://viacep.com.br/ws/${cep}/json/`)
    .then(response => response?.data)

  return resp
}

export const fetchCnpjInfo = async cnpj => {
  const resp = await axios
    .get(`${config.API_URL}/consult-cnpj`, {
      params: {
        cnpj,
      },
    })
    .then(response => response?.data)

  return resp
}

export const store = async formData => {
  const resp = await axios
    .post(`${config.API_URL}/laundry-eletronic-invoice`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(response => response?.data)

  return resp
}

export const show = async () => {
  const resp = await axios
    .get(`${config.API_URL}/laundry-eletronic-invoice`)
    .then(response => response?.data)

  return resp
}

export const showNfseConfig = async () => {
  const resp = await axios
    .get(`${config.API_URL}/laundry-nfse-config`)
    .then(response => response?.data)

  return resp
}

export const storeNfseConfig = async formData => {
  const resp = await axios
    .post(`${config.API_URL}/laundry-nfse-config`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(response => response?.data)

  return resp
}

/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable camelcase */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/tabindex-no-positive */
import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import React, { Component, useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Link, Route } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import {
  mdiCurrencyUsd,
  mdiCurrencyUsdOff,
  mdiFileDocumentCheckOutline,
} from '@mdi/js'
import styles from '../../../resources/theme/global'
import { renderRoute } from '../../../util/utils'
import { fetchBilling, getBillings } from '../BillingActions'
import { getBillingListData } from '../BillingReducer'

import Edit from './Edit'
import Filter from './Filter'
import List from './List'
import New from './New'
import CloseDetails from './CloseDetails'
import colors from '../../../assets/colors'
import Tabs from '../../../components/hooks/BaseTabs/Tabs'
import Tab from '../../../components/hooks/BaseTabs/Tab'
import { getPermission } from '../../attendance/AttendanceActions'

import BalanceList from './BalanceList'
import BankReportFilter from './BankReportFilter'
import BankTable from './BankTable'

const Billing = props => {
  const { location } = props
  const dispatch = useDispatch()
  const items = useSelector(state => state.billing.items)
  const historic = useSelector(state => state.billing.bankReport)

  const permissions = getPermission()

  const [value, setValue] = useState(0)

  useEffect(() => {
    const { state } = location

    if (state) {
      if (location?.state?.billing_type_generic == 'Receita') {
        setValue(1)
      }
    }
  }, [location])

  return (
    <>
      <Grid
        container
        // className={classes.pageHead}
        spacing={2}
        alignItems="center"
      >
        <Grid item xs={12}>
          <Typography
            variant="h4"
            style={{ fontSize: '20px' }}
            color={colors.primary}
          >
            Lançamentos
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Tabs>
            <Tab
              label="Despesas"
              value={value}
              tabIndex={0}
              setValue={setValue}
              icon={mdiCurrencyUsdOff}
              iconColor={colors.error}
              removeNavigation={false}
            />
            <Tab
              label="Receitas"
              value={value}
              tabIndex={1}
              setValue={setValue}
              icon={mdiCurrencyUsd}
              iconColor={colors.sucess}
              removeNavigation={false}
            />
            <Tab
              label="Extrato Bancário"
              value={value}
              setValue={setValue}
              tabIndex={2}
              icon={mdiFileDocumentCheckOutline}
              iconColor={colors.primary}
              removeNavigation={false}
            />
          </Tabs>

          {value === 0 && (
            <Grid container spacing={3} style={{ marginTop: '1em' }}>
              <Grid item xs={12}>
                <Filter
                  permissions={permissions}
                  typeGeneric="Despesa"
                  location={location}
                />
              </Grid>

              <Grid item xs={12}>
                <BalanceList
                  data={items || []}
                  permissions={permissions}
                  typeGeneric="Despesa"
                />
              </Grid>
            </Grid>
          )}

          {value === 1 && (
            <Grid container spacing={3} style={{ marginTop: '1em' }}>
              <Grid item xs={12}>
                <Filter
                  permissions={permissions}
                  typeGeneric="Receita"
                  location={location}
                />
              </Grid>

              <Grid item xs={12}>
                <BalanceList
                  type="revenues"
                  data={items || []}
                  permissions={permissions}
                  typeGeneric="Receita"
                />
              </Grid>
            </Grid>
          )}

          {value === 2 && (
            <Grid container spacing={3} style={{ marginTop: '1em' }}>
              <Grid item xs={12}>
                <BankReportFilter />
              </Grid>

              <Grid item xs={12}>
                <BankTable historic={historic} />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      {renderRoute(['create-billing'], permissions) && (
        <Route path="/billing/new" component={New} />
      )}

      {renderRoute(['update-billing'], permissions) && (
        <Route path="/billing/:id/edit" component={Edit} />
      )}

      {renderRoute(['read-billing'], permissions) && (
        <Route path="/billing/:id/close" component={CloseDetails} />
      )}

      {/* <List permissions={permissions} classes={classes} items={items} /> */}
    </>
  )
}

// const mapStateToProps = state => ({
//   ...getBillingListData(state),
//   permissions: state.auth.permissions || [],
// })

// const mapDispatchToProps = dispatch =>
//   bindActionCreators(
//     {
//       fetchBilling,
//     },
//     dispatch
//   )
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withStyles(styles)(Billing))

export default Billing

/* eslint-disable eqeqeq */
/* eslint-disable object-shorthand */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable react/prop-types */
import { MenuItem, Typography, makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import SearchIcon from '@material-ui/icons/Search'
import React, { useEffect, useState } from 'react'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'

import BaseFilter from '../../../components/hooks/BaseFilter'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import Icon from '@mdi/react'
import { mdiFilterMultiple, mdiMicrosoftExcel } from '@mdi/js'
import colors from '../../../assets/colors'
import BaseFilterModal from '../../../components/hooks/BaseFilterModal'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { fetchCommissionedPersons } from '../../attendance/AttendanceActions'
import SelectField from '../../components/SelectField'

const useStyles = makeStyles({
  modal: {
    width: '180px',
  },
})

const Filter = ({ form, setForm, handleReport }) => {
  const dispatch = useDispatch()

  const commissionedPersons = useSelector(
    state => state.attendance.commissionedPersons
  )

  const [open, setOpen] = useState(false)

  const classes = useStyles()

  const today = () => {
    const date = new Date()

    return format(date, 'yyyy-MM-dd', { locale: ptBR })
  }

  const [currentForm, setCurrentForm] = useState({
    person: '',
    commissioned_user_id: '',
    commissioned_client_id: '',
    commissioned_supplier_id: '',
    date_start: today(),
    date_end: today(),
    os: '',
  })

  const filterStyles = filterStylesComponents()

  const handleOpen = () => {
    setOpen(!open)
  }

  const handleChange = e => {
    e.preventDefault()

    const { name, value } = e.target

    setCurrentForm({
      ...currentForm,
      [name]: value,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()

    setForm({
      ...form,
      ...currentForm,
    })

    handleOpen()
  }

  useEffect(() => {
    if (currentForm.person) {
      const { id, type, table } = currentForm.person

      let field = {
        commissioned_user_id: id,
      }

      if (type == 2) {
        field = {
          commissioned_client_id: id,
        }
      } else if (type == 3) {
        field = {
          commissioned_supplier_id: id,
        }
      }

      setCurrentForm({
        ...currentForm,
        ...field,
      })
    }

    if (!currentForm.person) {
      setCurrentForm({
        ...currentForm,
        commissioned_user_id: '',
        commissioned_client_id: '',
        commissioned_supplier_id: '',
      })
    }
  }, [currentForm.person])

  useEffect(() => {
    setForm({
      ...form,
      commissioned_client_id: currentForm.commissioned_client_id,
      commissioned_supplier_id: currentForm.commissioned_supplier_id,
      commissioned_user_id: currentForm.commissioned_user_id,
    })
  }, [
    currentForm.commissioned_client_id,
    currentForm.commissioned_supplier_id,
    currentForm.commissioned_user_id,
  ])

  useEffect(() => {
    dispatch(fetchCommissionedPersons())
  }, [])

  return (
    <BaseFilter>
      <Grid container justifyContent="space-between">
        <Grid container xs={8} md={6} spacing={2}>
          <Grid item xs={12} sm={6}>
            <SelectField
              style={{ color: 'black', marginTop: '0px' }}
              options={commissionedPersons}
              setForm={person => {
                setCurrentForm({
                  ...currentForm,
                  person: person,
                })
              }}
              name="person"
              data={currentForm?.person}
              uniqueState
              label="Comissionado"
            />
          </Grid>
        </Grid>

        <Grid container xs={4} md={6} className={filterStyles.areaButtons}>
          <IconButton
            className={filterStyles.btnMoreFilters}
            onClick={handleOpen}
          >
            <Icon path={mdiFilterMultiple} size={1} color={colors.secondary} />
            Filtros
          </IconButton>

          <IconButton className={filterStyles.btnAdd} onClick={handleReport}>
            <Icon path={mdiMicrosoftExcel} size={1} />
            Donwload
          </IconButton>
        </Grid>
      </Grid>

      <BaseFilterModal title="FILTRO" open={open} handleOpen={handleOpen}>
        <Grid item xs={12}>
          <Typography
            style={{ fontSize: '16px', fontWeight: 'normal' }}
            color={colors.primary}
          >
            Utilize os campos abaixo para encontrar <br /> uma movimentação
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.modal}>
          <Grid
            container
            className={classes.gridContainer}
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={6}>
              <SelectField
                style={{ color: 'black', marginTop: '0px' }}
                options={commissionedPersons}
                setForm={person => {
                  setForm({
                    ...form,
                    person: person,
                  })
                }}
                name="person"
                data={currentForm.person}
                uniqueState
                label="Comissionado"
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                type="number"
                label="Ordem de serviço"
                className={classes.inputFilter}
                fullWidth
                value={currentForm?.os}
                onChange={handleChange}
                name="os"
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                type="date"
                label="Data de começo"
                className={classes.inputFilter}
                fullWidth
                value={currentForm.date_start}
                onChange={handleChange}
                name="date_start"
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                type="date"
                label="Data de fim"
                className={classes.inputFilter}
                fullWidth
                value={currentForm.date_end}
                onChange={handleChange}
                name="date_end"
              />
            </Grid>

            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
              style={{ marginTop: '1.5em' }}
            >
              <IconButton
                type="submit"
                color="primary"
                fullWidth
                variant="contained"
                className={filterStyles.btnSalvar}
                onClick={handleSubmit}
              >
                <SearchIcon />
                Pesquisar
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </BaseFilterModal>
    </BaseFilter>
  )
}

export default Filter

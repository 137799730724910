/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable import/order */
/* eslint-disable import/no-duplicates */
/* eslint-disable prettier/prettier */
import React, { useState } from 'react'
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  makeStyles,
} from '@material-ui/core'
import { Grid, Paper, Typography } from '@material-ui/core'
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined'
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUp'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import ReactInputMask from 'react-input-mask'
import { isCpfCnpjValid } from '../../../../util/cpf_cnpj_validator'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '.5rem',
    padding: '1rem',
    border: '1px solid #539fdd',
  },
})

const FormGroupUser = () => {
  const classes = useStyles()

  const [cpfCnpj, setCpfCnpj] = useState({
    error: false,
    text: 'Digite um CPF',
  })

  const [values, setValues] = useState({
    showPassword: false,
  })

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const handleValidCpfCnpj = e => {
    const { value } = e.target

    if (value.length == 14) {
      if (isCpfCnpjValid(value)) {
        setCpfCnpj({
          ...cpfCnpj,
          error: false,
          text: '',
        })
      } else {
        setCpfCnpj({
          ...cpfCnpj,
          error: true,
          text: 'CPF inválido',
        })
      }
    }
  }

  return (
    <Paper className={classes.root}>
      <Typography
        variant="h5"
        style={{ width: '100%' }}
        gutterBottom
        color="primary"
      >
        Dados do gerente
      </Typography>

      <Grid container spacing={2}>
        <Grid item sm={12} xs={12} lg={4}>
          <TextField
            label="Nome"
            name="gerente_name"
            variant="outlined"
            required
            fullWidth
            className={classes?.textField}
          />
        </Grid>

        <Grid item sm={12} xs={12} lg={4}>
          <TextField
            label="Email"
            type="email"
            name="gerente_email"
            variant="outlined"
            required
            fullWidth
            className={classes?.textField}
          />
        </Grid>

        <Grid item sm={12} xs={12} lg={4}>
          <ReactInputMask
            mask="999.999.999-99"
            maskChar=""
            name="gerente_cpf"
            onBlur={handleValidCpfCnpj}
            required
          >
            {inputProps => (
              <TextField
                {...inputProps}
                label="CPF"
                fullWidth
                variant="outlined"
                error={cpfCnpj?.error}
                helperText={cpfCnpj?.text}
              />
            )}
          </ReactInputMask>
        </Grid>

        <Grid item sm={12} xs={12} lg={4}>
          <OutlinedInput
            name="gerente_password"
            placeholder="password"
            fullWidth
            required
            type={values.showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment>
                <IconButton
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default FormGroupUser

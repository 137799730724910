import {
  Box,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import HistoryIcon from '@material-ui/icons/History'
import { useDispatch, useSelector } from 'react-redux'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import { useConfirm } from 'material-ui-confirm'

import { SNACKBAR } from '../../main/MainActions'
import { edit, getHistory } from '../AttendanceActions'
import { dateFormatedFullDate, exportFormatedMoney } from '../../../util/utils'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  table: {
    padding: theme.spacing(2),
  },
}))

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />
})

function Historic({ disabled, clientId, canClick, fillNewServices }) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const historic = useSelector(state => state.attendance.historic)
  const confirm = useConfirm()

  const dispatch = useDispatch()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  function createData(
    id,
    createdAt,
    totalWithDiscount,
    totalWithoutDiscount,
    totalHours,
    status,
    generalDiscount,
    sallesMan,
    services
  ) {
    return {
      id,
      createdAt,
      totalWithDiscount,
      totalWithoutDiscount,
      totalHours,
      status,
      generalDiscount,
      sallesMan,
      services,
    }
  }

  const rows = []

  historic?.map(row => {
    rows.push(
      createData(
        row.id,
        row.created_at,
        row.total_with_discount,
        row.total_without_discount,
        row.total_hours,
        row.status,
        row.general_discount,
        row.salles_man,
        row.services
      )
    )
  })

  const confirmCopy = id => {
    confirm({
      description: `Deseja realmente copiar os itens da OS ${id} para a nova OS?`,
      title: 'Tem certeza?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar',
      dialogProps: {
        fullWidth: true,
      },
    }).then(async () => {
      const item = historic.find(x => x.id === id)

      const { data } = await dispatch(edit(item.id))

      fillNewServices(data)
      setOpen(false)

      dispatch({
        type: SNACKBAR.SOFTSUCCESS,
        success: { message: 'A Nova OS foi populada com os itens' },
      })
    })
  }

  function Row(props) {
    const { row } = props
    const [open, setOpen] = React.useState(false)
    const classes = useRowStyles()

    return (
      <>
        <TableRow className={classes.root}>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.id}
          </TableCell>
          <TableCell align="right">
            {dateFormatedFullDate(row.createdAt)}
          </TableCell>
          <TableCell align="right">
            {exportFormatedMoney(row.totalWithDiscount)}
          </TableCell>
          <TableCell align="right">
            {exportFormatedMoney(row.totalWithoutDiscount)}
          </TableCell>
          <TableCell align="right">{`${row.generalDiscount || 0}%`}</TableCell>
          <TableCell align="right">{row.totalHours}</TableCell>
          <TableCell align="right">{row.status}</TableCell>
          <TableCell align="right">{row.sallesMan}</TableCell>
          <TableCell align="right">
            <IconButton onClick={() => confirmCopy(row.id)} disabled={canClick}>
              <FileCopyIcon color="primary" />
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Itens
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell align="right">Valor</TableCell>
                      <TableCell align="right">Desconto</TableCell>
                      <TableCell align="right">Tempo de Ex</TableCell>
                      <TableCell>Serviço</TableCell>
                      <TableCell>Quantidade/Tamanho</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.services.map(service => (
                      <TableRow key={service.id}>
                        <TableCell component="th" scope="row">
                          {service.id}
                        </TableCell>
                        <TableCell align="right">
                          {exportFormatedMoney(service.final_value)}
                        </TableCell>
                        <TableCell align="right">{service.discount}</TableCell>
                        <TableCell align="right">
                          {service.execution_time}
                        </TableCell>
                        <TableCell>{service.serviceType}</TableCell>
                        <TableCell>{service.quantity}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    )
  }

  useEffect(() => {
    if (open && clientId) dispatch(getHistory(clientId))
  }, [open])

  return (
    <div>
      <IconButton
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
        disabled={disabled}
      >
        <HistoryIcon />
      </IconButton>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Histórico do cliente
            </Typography>
          </Toolbar>
        </AppBar>
        <Paper elevation={3}>
          <TableContainer className={classes.table}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>ID</TableCell>
                  <TableCell align="right">Data do serviço</TableCell>
                  <TableCell align="right">Total c/ desc</TableCell>
                  <TableCell align="right">Total s/ desc</TableCell>
                  <TableCell align="right">Desc</TableCell>
                  <TableCell align="right">Tempo gasto</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">Vendedor</TableCell>
                  <TableCell align="right">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map(row => (
                  <Row key={row.id} row={row} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Dialog>
    </div>
  )
}

export default React.memo(Historic)

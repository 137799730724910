/* eslint-disable import/order */
import { combineReducers } from 'redux'

import auth from '../auth/AuthReducer'
import client from '../clients/ClientReducer'
import laundryClient from '../laundryClients/LaundryClientReducer'
import LaundryClientBillings from '../laundryClientBillings/LaundryClientBillingsReducer'
// import measureType from '../measure-type/MeasureTypeReducer'
// import role from '../roles/RolesReducer'
import serviceCategory from '../service-category/ServiceCategoryReducer'
import serviceType from '../service-type/ServiceTypeReducer'
import paymentMethod from '../payment-type/PaymentTypeReducers'
import attendance from '../attendance/AttendanceReducer'
import calendar from '../callendar/CalendarReducer'
import user from '../users/UserReducer'
import main from './MainReducer'
// import laundry from '../laundry/LaundryReducer'
// import attendanceLaundry from '../attendance-laundry/AttendanceLaundryReducer'
import birth_relatory from '../birthRelatory/BirthRelatoryReducer'
import clients_relatory from '../clientsRelatory/ClientsRelatoryReducer'
import prospects_relatory from '../prospectsRelatory/ProspectsRelatoryReducer'
import services_relatory from '../servicesRelatory/ServicesRelatoryReducer'
import schedules_relatory from '../schedulesRelatory/SchedulesRelatoryReducer'
import service_categories_relatory from '../serviceCategoriesRelatory/ServiceCategoriesRelatoryReducer'
import team_produtivity_relatory from '../teamProdutivityRelatory/TeamProdutivityRelatoryReducer'
import financial_relatory from '../financialRelatory/FinancialRelatoryReducer'
import dashboard from '../home/HomeReducer'
import supplier from '../supplier/SupplierReducer'
import billingType from '../billingType/BillingTypeReducer'
import billing from '../billing/BillingReducer'
// import config from '../config/ConfigReducer'
import financialDashboard from '../financialDashboard/FinancialDashboardReducer'
// import company from '../companies/CompaniesReducer'
// import mapLaundry from '../map/MapReducer'
import paymentVerification from '../payment-verification/PaymentVerificationReducer'
// import laundryDashboard from '../laundryDashboard/LaundryDashboardReducer'
// import restoration from '../restoration/RestorationReducer'
import bank from '../bank/BankReducer'
import bankReport from '../bank-report/BankReportReducer'
import knowWay from '../knowWay/KnowWayReducer'
import subscription from '../subscription/SubscriptionReducer'
import stock from '../stock/StockReducer'
import stockRelatory from '../StockRelatory/StockRelatoryReducer'
import productHistory from '../productHistory/ProductHistoryReducer'
import commissionRelatory from '../relatoryCommission/RelatoryCommissionReducer'

const rootReducer = combineReducers({
  main,
  auth,
  user,
  client,
  laundryClient,
  LaundryClientBillings,
  // role,
  serviceCategory,
  // measureType,
  serviceType,
  paymentMethod,
  attendance,
  calendar,
  // laundry,
  // attendanceLaundry,
  birth_relatory,
  clients_relatory,
  prospects_relatory,
  services_relatory,
  schedules_relatory,
  service_categories_relatory,
  team_produtivity_relatory,
  financial_relatory,
  dashboard,
  supplier,
  billingType,
  billing,
  // config,
  financialDashboard,
  // company,
  // mapLaundry,
  paymentVerification,
  // laundryDashboard,
  // restoration,
  bank,
  bankReport,
  knowWay,
  subscription,
  stock,
  stockRelatory,
  productHistory,
  commissionRelatory,
})

export default rootReducer

/* eslint-disable react/prop-types */
import { Tooltip, makeStyles } from '@material-ui/core'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import React from 'react'
import { Link } from 'react-router-dom'

import colors from '../../assets/colors'

const useStyles = makeStyles({
  listItem: {
    color: colors.textPrimary,
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: 'inherit',
      color: colors.primary,
    },
    padding: '6px 16px',
  },
  listItemIcon: {
    minWidth: '24px',
  },
  listItemText: {
    paddingLeft: '10px',
    fontWeight: '700',
  },
})

export default props => {
  const { route, handle, expand, title, icon, open } = props

  const classes = useStyles()

  return (
    <Tooltip title={open ? '' : title} arrow placement="left">
      <ListItem
        button
        component={route ? Link : null}
        to={route}
        onClick={handle}
        className={classes.listItem}
      >
        <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
        {open ? (
          <ListItemText
            primary={title}
            className={classes.listItemText}
            primaryTypographyProps={classes.listItemText}
            disableTypography={icon}
          />
        ) : (
          ''
        )}
        {expand}
      </ListItem>
    </Tooltip>
  )
}

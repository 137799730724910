/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/order */
import React, { useEffect, useState } from 'react'
import { Box, Button, Container, Grid, makeStyles } from '@material-ui/core'
import FormGroupLaundry from './FormGroupLaundry'
import FormGroupUser from './FormGroupUser'
import { Link as RouteLink } from 'react-router-dom'
import axios from 'axios'
import config from '../../../../config'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Alert } from '@material-ui/lab'
import Snackbar from '@material-ui/core/Snackbar'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

const useStyles = makeStyles({
  container: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fafafa',
  },
  box: {
    width: '95%',
    height: '90%',
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    overflowY: 'scroll',
    borderRadius: '.5rem',
    boxShadow: '0  0 .2rem .2rem rgb(0, 0, 0, .2)',
    backgroundColor: '#FFF',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  link: {
    backgroundColor: '#539fdd',
    color: '#FFF',
    padding: '.5rem 1rem',
    transition: 'all .6s',
    '&:hover': {
      opacity: '.8',
    },
  },
  routeLink: {
    textDecoration: 'none',
    padding: '.5rem',
    borderRadius: '.5rem',
    color: '#539fdd',
    transition: 'all .6s',
    '&:hover': {
      backgroundColor: '#539fdd',
      color: '#FFF',
    },
  },
})

export const createLaundry = () => {
  const classes = useStyles()
  const history = useHistory()

  const [open, setOpen] = useState(false)

  const handleFormSubmit = async e => {
    e.preventDefault()

    const form = e.currentTarget
    const data = new FormData(form)

    try {
      const resp = await axios.post(`${config.API_URL}/new-laundry`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      if (resp?.data?.message) {
        toast.success(
          `Sucesso! Seu teste foi iniciado, aguarde e será redirecionado.`,
          {
            position: 'top-right',
            hideProgressBar: false,
            autoClose: 5000,
          }
        )
      }
      setTimeout(() => {
        history.push({
          pathname: `/`,
          state: {
            email: data?.get('gerente_email'),
            password: data?.get('gerente_password'),
          },
        })
      }, 6000)
    } catch (e) {
      if (e.response?.data?.errors) {
        Object.values(e.response?.data?.errors).map(errors =>
          errors.map(e =>
            toast.error(e, { position: 'bottom-right', transition: 'slide' })
          )
        )
      } else if (e.response?.data?.message) {
        toast.error(e.response?.data?.message, {
          position: 'bottom-right',
          transition: 'slide',
        })
      }
    }
  }

  return (
    <Container className={classes.container}>
      <Box className={classes.box}>
        <form className={classes.form} onSubmit={handleFormSubmit}>
          <FormGroupLaundry />

          <FormGroupUser />

          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <RouteLink to="/" className={classes?.routeLink}>
                Voltar para tela de login
              </RouteLink>
            </Grid>

            <Grid item>
              <Button type="submit" className={classes?.link}>
                Enviar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>

      <ToastContainer />
    </Container>
  )
}

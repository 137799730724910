/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/extensions */
import React, { useEffect, useState } from 'react'
import EventIcon from '@material-ui/icons/Event'
import { useDispatch, useSelector } from 'react-redux'
import { useConfirm } from 'material-ui-confirm'
import { format } from 'date-fns'
import ArchiveIcon from '@material-ui/icons/Archive'
import queryString from 'query-string'

import {
  remove,
  fetchAttendanceInit,
  eraseFilters,
  archive,
} from '../AttendanceActions'
import { exportFormatedMoney, dateFormatedFullDate } from '../../../util/utils'
import consts from '../../../util/consts'

import GlobalList from './ListTable.jsx'
import { mdiCalendarClock } from '@mdi/js'

function createData(
  id,
  id_laundry_client,
  name,
  status,
  value,
  items,
  canRender,
  hasBilling,
  statusId,
  nfseStatus,
  nfseStatusId,
  paymentCompleted,
  userName,
  createdAt
) {
  return {
    id,
    id_laundry_client,
    items,
    name,
    value,
    userName,
    createdAt,
    status,
    nfseStatus,
    nfseStatusId,
    hasBilling,
    statusId,
    update: 'update-attendances',
    delete: 'delete-attendances',
    finance: 'create-billing-client',
    link_update: `/attendance/${id}/edit`,
    link_finance: `/attendance/${id}/payment`,
    canRender,
    paymentCompleted,
  }
}

const headCells = [
  {
    id: 'id',
    numeric: true,
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'id_laundry_client',
    numeric: true,
    disablePadding: false,
    label: 'Nº OS',
  },
  {
    id: 'items',
    numeric: true,
    disablePadding: false,
    label: 'Itens',
  },
  {
    id: 'name',
    numeric: true,
    disablePadding: false,
    label: 'Cliente',
  },
  {
    id: 'value',
    numeric: true,
    disablePadding: false,
    label: 'Valor',
    align: 'center',
  },
  {
    id: 'userName',
    numeric: true,
    disablePadding: false,
    label: 'Vendedor',
  },
  // {
  //   id: 'value',
  //   numeric: true,
  //   disablePadding: false,
  //   label: 'Valor',
  // },
  {
    id: 'createdAt',
    numeric: true,
    disablePadding: false,
    label: 'Data',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
    align: 'center',
  },
  {
    id: 'nfseStatus',
    numeric: true,
    disablePadding: false,
    label: 'NFSe',
    align: 'center',
  },
  {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    label: 'Ações',
  },
]

function List(props) {
  const { items, location } = props
  const rows = []
  const dispatch = useDispatch()
  const confirm = useConfirm()
  const sizePerPage = useSelector(state => state.attendance.sizePerPage)
  const page = useSelector(state => state.attendance.page)
  const totalSize = useSelector(state => state.attendance.totalSize)
  const [rowsPerPage, setRowsPerPage] = useState(sizePerPage)
  const filters = useSelector(state => state.attendance.filters)

  items.map(item => {
    return rows.push(
      createData(
        item.id,
        item.id_laundry_client,
        item.client.name,
        item.status.name,
        exportFormatedMoney(item.total_with_discount),
        item.items.length,
        item.can_edit,
        item.has_billing,
        item.status.id,
        item?.nfse_status?.value,
        item?.nfse_status_id,
        item.payment_completed,
        item.user?.name,
        dateFormatedFullDate(item.created_at)
      )
    )
  })

  const onMovePage = async (event, newPage) => {
    const localFilters = {
      ...filters,
      status_id: location?.state?.status || '',
      date_end: location?.state?.date_end || '',
      date_start: location?.state?.date_start || '',
    }

    await dispatch(fetchAttendanceInit(localFilters, rowsPerPage, newPage + 1))
  }

  useEffect(() => {
    onMovePage()
  }, [rowsPerPage])

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value)
  }

  const otherIcons = [
    {
      link: id => `/calendar/${id}?os=${id}`,
      ariaLabel: 'calendar',
      completeID: true,
      icon: mdiCalendarClock,
    },
  ]

  const otherIconsClick = [
    {
      onClick: async id => {
        confirm({
          description: 'Deseja arquivar este orçamento?',
          title: 'Tem certeza?',
          confirmationText: 'Sim',
          cancellationText: 'Cancelar',
          dialogProps: {
            fullWidth: true,
          },
        }).then(async () => {
          const resp = await dispatch(archive(id))
          if (resp.status === 200) {
            onMovePage(null, page - 1)
          }
        })
      },
      renderRule: id => {
        return id === consts.ORCAMENTO
      },
      ariaLabel: 'archive',
      icon: <ArchiveIcon />,
    },
  ]

  return (
    <GlobalList
      {...props}
      onMovePage={onMovePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      headCells={headCells}
      rows={rows}
      remove={remove}
      rowsPerPage={rowsPerPage}
      page={page - 1}
      totalSize={totalSize}
      otherIcons={otherIcons}
      otherIconsClick={otherIconsClick}
    />
  )
}

export default List

/* eslint-disable no-unused-vars */
/* eslint-disable import/newline-after-import */
/* eslint-disable import/extensions */
/* eslint-disable import/order */
/* eslint-disable no-shadow */
import { Grid } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import React, { Component } from 'react'
import { connect, useSelector } from 'react-redux'
import { Link, Route } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import styles from '../../../resources/theme/global.js'
import { renderRoute } from '../../../util/utils.js'
import { fetchAttendanceInit } from '../AttendanceActions.js'
import { getAttendanceListData } from '../AttendanceReducer.js'

import Edit from './Edit.js'
import Filter from './Filter.jsx'
import List from './List.jsx'
import New from './New.js'
import Payment from './PaymentDetails.js'

import colors from '../../../assets/colors.jsx'
import ModalShowVisits from './ModalShowVisits.js'
import ModalEletronicInvoice from './ModalEletronicInvoice.jsx'
class Attendance extends Component {
  componentDidMount() {
    // const { fetchAttendanceInit } = this.props
    // fetchAttendanceInit()
  }

  render() {
    const { classes, permissions, items } = this.props

    return (
      <>
        <Grid
          container
          className={classes.pageHead}
          spacing={2}
          alignItems="center"
        >
          <Grid item xs={12}>
            <Typography
              variant="h4"
              style={{ fontSize: '20px' }}
              color={colors.primary}
            >
              Atendimentos
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Filter
              classes={classes}
              permissions={permissions}
              {...this.props}
            />
          </Grid>
        </Grid>

        {renderRoute(['create-attendances'], permissions) && (
          <Link to="/attendance/new" />
        )}

        {renderRoute(['update-attendances'], permissions) && (
          <Link to="/attendance/:id/edit" />
        )}

        {renderRoute(['create-billing-client'], permissions) && (
          <Route path="/attendance/:id/payment" component={Payment} />
        )}

        {renderRoute(['read-attendances'], permissions) && (
          <Route path="/attendance/visits/:id" component={ModalShowVisits} />
        )}

        {renderRoute(['read-attendances'], permissions) && (
          <Route
            path="/attendance/eletronic-invoice/:id"
            component={ModalEletronicInvoice}
          />
        )}

        <List {...this.props} items={items} />
      </>
    )
  }
}

const mapStateToProps = state => ({
  ...getAttendanceListData(state),
  permissions: state.auth.permissions || [],
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAttendanceInit,
    },
    dispatch
  )
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Attendance))

/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable import/order */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useEffect, useRef, useState } from 'react'

import BaseCreatePage from '../../../components/hooks/BaseCreatePage'
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core'
import colors from '../../../assets/colors'
import ReactInputMask from 'react-input-mask'
import Tabs from '../../../components/hooks/BaseTabs/Tabs'
import Tab from '../../../components/hooks/BaseTabs/Tab'
import Icon from '@mdi/react'
import { mdiProgressUpload } from '@mdi/js'
import axios from 'axios'
import { isCpfCnpjValid } from '../../../util/cpf_cnpj_validator'
import { SNACKBAR } from '../../main/MainActions'
import { useDispatch } from 'react-redux'
import {
  fetchCnpjInfo,
  fetchViaCep,
  show,
  store,
} from '../LaundryEletronicInvoiceActions'
import Form from './Form'
import NfseConfigForm from './NfseConfigForm'

const useStyles = makeStyles({
  subTitle: {
    padding: '8px 0',
    borderTop: '1px dashed',
    borderColor: colors.textSecondary,
    borderBottom: '1px dashed',
    borderBottomColor: colors.textSecondary,
    color: colors.textPrimary,
    fontSize: '14px',
  },
  company_box: {
    padding: '8px',
    borderRadius: '3px',
    boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.2)',
  },
  details_box: {
    padding: '12px',
    borderRadius: '3px',
    boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.2)',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  btnSave: {
    borderRadius: '20px',
    color: '#FFF',
    backgroundColor: colors.primary,
    fontSize: '12px',
    textTransform: 'capitalize',
    width: '101px',
    padding: '4px',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: colors.primary,
      opacity: '.7',
    },
  },
  uploadLogo: {
    width: '100%',
    height: '100%',
    border: `1px dashed ${colors.primary}`,
    padding: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '4px',
    fontSize: '14px',
    borderRadius: '10px',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: '#FFF',
      opacity: '.6',
    },
  },
  certificateStatus: {
    fontSize: '12px',
    color: colors.graylight,
  },
  cartifacateValidate: {
    backgroundColor: colors.bgSucess,
    borderRadius: '15px',
    color: colors.primary,
    fontSize: '12px',
  },
})

const LaundryEletronicInvoice = () => {
  const classes = useStyles()

  const [loading, setLoading] = useState(false)

  const [haveRegistration, setHaveRegistration] = useState(false)

  const [form, setForm] = useState({
    cnpj: '',
    nome: '',
    validade_certificado: '',
  })

  const verifyIfHaveRegistration = async () => {
    setLoading(true)

    try {
      const resp = await show()

      if (resp?.data) {
        const { data } = resp

        setForm({
          ...form,
          nome: data?.razao_social,
          cnpj: data?.cnpj,
          validade_certificado: data?.validade_certificado,
        })

        setHaveRegistration(true)
      }
    } catch (e) {
      setLoading(false)
      //   console.log(e)
    }

    setLoading(false)
  }

  useEffect(() => {
    verifyIfHaveRegistration()
  }, [])

  return (
    <BaseCreatePage
      page="Lavanderia"
      path="Dados do cadastro de NFSe"
      pageLink="/"
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box className={classes.subTitle}>
            Integração do sistema para geração de NFSe
          </Box>
        </Grid>

        <Grid item xs={12}>
          {!loading && (
            <Form
              haveRegistration={haveRegistration}
              reloadVerifyRegistratiron={verifyIfHaveRegistration}
              registrationData={form}
            />
          )}
        </Grid>

        {haveRegistration && (
          <Grid item xs={12}>
            <NfseConfigForm />
          </Grid>
        )}
      </Grid>
    </BaseCreatePage>
  )
}

export default LaundryEletronicInvoice

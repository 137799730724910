/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/order */
/* eslint-disable import/no-duplicates */
/* eslint-disable prettier/prettier */
import React, { useState } from 'react'
import {
  FormControlLabel,
  TextField,
  Select,
  InputLabel,
  MenuItem,
} from '@material-ui/core'
import { IconButton, makeStyles } from '@material-ui/core'
import { Grid, Paper, Typography } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import ThumbDownAltOutlinedIcon from '@material-ui/icons/ThumbDownAltOutlined'
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUp'
import { PhotoCamera } from '@material-ui/icons'
import ReactInputMask from 'react-input-mask'
import axios from 'axios'
import ufs from '../../../../util/uf'
import { isCpfCnpjValid } from '../../../../util/cpf_cnpj_validator'
import config from '../../../../config'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '.5rem',
    padding: '.5rem',
    border: '1px solid #539fdd',
  },
  row: {
    width: '100%',
    padding: '.5rem',
  },
})

const FormGroupLaundry = () => {
  const classes = useStyles()

  const [mask, setMask] = useState('999.999.999-99')
  const [cpfCnpj, setCpfCnpj] = useState({
    error: false,
    text: 'Digite um CPF ou CNPJ',
  })
  const [logoValidate, setLogoValidate] = useState({
    invalidType: '',
  })

  const [form, setForm] = useState({
    cep: '',
    city: '',
    uf: '',
    street: '',
    neighborhood: '',
    phone: '',
  })

  const handlePhoneChange = e => {
    const { name, value } = e.target
    const phoneWithoutMask = value.replace(/\D/g, '')

    setForm({
      ...form,
      [name]: phoneWithoutMask,
    })
  }

  const handleChangeLogo = event => {
    if (event.target.files[0] == null) {
      return
    }
    if (
      event.target.files[0].type != 'image/png' &&
      event.target.files[0].type != 'image/jpeg'
    ) {
      setLogoValidate({
        ...logoValidate,
        invalidType: 'Tipo de arquivo inválido',
      })
    } else {
      setLogoValidate({
        ...logoValidate,
        invalidType: '',
      })
    }
  }

  const buscaCep = async cep => {
    const currentCep = cep?.replace(/\D/g, '')

    try {
      if (currentCep?.length == 8) {
        const resp = await axios
          .get(`https://viacep.com.br/ws/${currentCep}/json/`)
          .then(response => response?.data)

        setForm({
          ...form,
          city: resp?.localidade,
          uf: resp?.uf,
          street: resp?.logradouro,
          neighborhood: resp?.bairro,
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleChangeCpf = e => {
    const { name, value } = e.target

    if (value.length >= 15) {
      setMask('99.999.999/9999-99')
    } else {
      setMask('999.999.999-999')
    }
  }

  const handleValidCpfCnpj = e => {
    const { name, value } = e.target

    if (value.length == 14) {
      if (isCpfCnpjValid(value)) {
        setCpfCnpj({
          ...cpfCnpj,
          error: false,
          text: '',
        })
      } else {
        setCpfCnpj({
          ...cpfCnpj,
          error: true,
          text: 'CPF inválido',
        })
      }
    } else if (value.length == 18) {
      if (isCpfCnpjValid(value)) {
        setCpfCnpj({
          ...cpfCnpj,
          error: false,
          text: '',
        })
      } else {
        setCpfCnpj({
          ...cpfCnpj,
          error: true,
          text: 'CNPJ inválido',
        })
      }
    } else {
      setCpfCnpj({
        ...cpfCnpj,
        error: false,
        text: 'Digite um CPF ou CNPJ válido',
      })
    }
  }

  const handleChange = e => {
    e.preventDefault()
    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value,
    })
  }

  return (
    <Paper className={classes.root}>
      <Typography
        variant="h5"
        style={{ width: '100%' }}
        gutterBottom
        color="primary"
      >
        Dados da lavanderia
      </Typography>

      <Grid container sm={12} xs={12} lg={12} spacing={2}>
        <Grid item sm={12}>
          <TextField
            label="Nome"
            name="name"
            variant="outlined"
            required
            fullWidth
            className={classes?.textField}
          />
        </Grid>

        <Grid item sm={12}>
          <TextField
            label="E-mail"
            fullWidth
            name="email"
            variant="outlined"
            className={classes.textField}
            required
          />
        </Grid>

        <Grid item sm={12}>
          <Grid>
            <label>Logo (169 x 32): {logoValidate?.invalidType}</label>
          </Grid>

          <input
            name="logo"
            type="file"
            accept="image/*"
            onChange={e => handleChangeLogo(e)}
          />

          <label htmlFor="icon-button-photo">
            <IconButton color="primary" component="span">
              <PhotoCamera />
            </IconButton>
          </label>
        </Grid>
      </Grid>

      <Grid className={classes.row}>
        <Grid container spacing={2}>
          <Grid item sm={5} xs={12} lg={4}>
            <ReactInputMask
              mask="99.999-999"
              name="cep"
              maskChar=""
              value={form?.cep}
              required
              onChange={handleChange}
              onBlur={() => buscaCep(form?.cep)}
            >
              {inputProps => (
                <TextField {...inputProps} label="CEP" fullWidth />
              )}
            </ReactInputMask>
          </Grid>

          <Grid item sm={12} xs={12} lg={4}>
            <TextField
              label="Rua"
              name="street"
              fullWidth
              value={form?.street}
              required
            />
          </Grid>

          <Grid item sm={12} xs={12} lg={4}>
            <TextField label="Número" name="number" fullWidth required />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item sm={12} xs={12} lg={4}>
            <TextField
              name="neighborhood"
              label="Bairro"
              fullWidth
              required
              value={form?.neighborhood}
            />
          </Grid>

          <Grid item sm={12} xs={12} lg={4}>
            <TextField name="complement" label="Complemento" fullWidth />
          </Grid>

          <Grid item sm={12} xs={12} lg={4}>
            <TextField
              name="city"
              label="Cidade"
              fullWidth
              required
              value={form?.city}
            />
          </Grid>

          <Grid item sm={12} xs={12} lg={4}>
            <InputLabel id="demo-simple-select-required-label">UF</InputLabel>
            <Select
              labelId="demo-simple-select-required-label"
              id="demo-simple-select-required"
              value={form?.uf}
              fullWidth
              name="uf"
              required
              onChange={handleChange}
            >
              {ufs?.map((uf, index) => {
                return (
                  <MenuItem key={index} value={uf}>
                    {uf}
                  </MenuItem>
                )
              })}
            </Select>
          </Grid>

          <Grid item sm={12} xs={12} lg={4}>
            <ReactInputMask
              mask="(99) 9999-9999"
              placeholder="(__) ____-____"
              name="phone"
              maskChar=""
              value={form?.phone}
              required
              onChange={handlePhoneChange}
            >
              {inputProps => (
                <TextField {...inputProps} label="Telefone" fullWidth />
              )}
            </ReactInputMask>
          </Grid>

          <Grid item sm={12} xs={12} lg={4}>
            <ReactInputMask
              mask={mask}
              maskChar=""
              name="cpf_cnpj"
              value={form?.cpf_cnpj}
              onChange={e => handleChangeCpf(e)}
              onBlur={e => handleValidCpfCnpj(e)}
              required
            >
              {inputProps => (
                <TextField
                  {...inputProps}
                  label="CPF/CNPJ"
                  fullWidth
                  error={cpfCnpj?.error}
                  helperText={cpfCnpj?.text}
                />
              )}
            </ReactInputMask>
          </Grid>

          <Grid item xs={12} style={{ marginTop: '20px' }}>
            <TextField
              placeholder="Descrição"
              name="description"
              variant="outlined"
              multiline
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default FormGroupLaundry

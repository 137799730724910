/* eslint-disable eqeqeq */
/* eslint-disable camelcase */
/* eslint-disable import/order */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { FaFileDownload } from 'react-icons/fa'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  IconButton,
  Grid,
  Typography,
  InputBase,
  TextField,
  Button,
} from '@material-ui/core'

import axios from 'axios'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useDispatch } from 'react-redux'
import { SNACKBAR } from '../../main/MainActions'
import colors from '../../../assets/colors'
import config from '../../../config'
import BaseFilterModal from '../../../components/hooks/BaseFilterModal'
import classNames from 'classnames'
import NumberFormatCustom from '../../components/NumberFormat'
import Icon from '@mdi/react'
import { mdiInvoiceTextCheckOutline } from '@mdi/js'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import ReactInputMask from 'react-input-mask'
import { isCpfCnpjValid } from '../../../util/cpf_cnpj_validator'

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: '1rem',
    borderRadius: '.5rem',
    width: '50%',
    height: '70%',
    border: `2px solid ${colors.secondary}`,
  },
  title: {
    fontSize: '1.5rem',
    fontFamily: 'sans-serif',
    textAlign: 'center',
    color: colors.primary,
  },
  tableContainer: {
    maxHeight: '100%',
    overflow: 'auto',
  },
  tableHeadCell: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
  },
  container: {
    width: '470px',
  },
}))

const ModalEletronicInvoice = props => {
  const classes = useStyles()

  const history = useHistory()
  const dispatch = useDispatch()
  const filterClasses = filterStylesComponents()

  const [attendance, setAttendance] = useState()

  const { id } = props.match.params

  const [mask, setMask] = useState('999.999.999-99')

  const [form, setForm] = useState({
    description: '',
    value: '',
    cpf_cnpj: '',
  })

  const [cpfCnpj, setCpfCnpj] = useState({
    error: false,
    text: 'Digite um CPF ou CNPJ',
  })

  const fetchAttedance = async () => {
    try {
      const resp = await axios.get(`${config.API_URL}/attendance/${id}`)

      setAttendance(resp?.data?.data)
    } catch (e) {
      dispatch({
        type: SNACKBAR.HARDFAIL,
        error: {
          message: e?.response?.data?.message,
        },
      })
    }
  }

  const handleClose = () => {
    history.push('/attendance')
  }

  const handleChange = e => {
    e.preventDefault()

    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value,
    })
  }

  const handleInitDescription = () => {
    const { services } = attendance

    if (Array.isArray(services)) {
      let servicesDescription = ''

      services?.map(serviceItem => {
        servicesDescription += `- ${serviceItem?.name} ${' '}`
      })

      setForm({
        ...form,
        description: servicesDescription,
        value: attendance?.total_with_discount,
        cpf_cnpj: attendance?.client?.cpf_cnpj,
      })

      if (attendance?.client?.cpf_cnpj?.length >= 15) {
        setMask('99.999.999/9999-99')
      } else {
        setMask('999.999.999-999')
      }
    }
  }

  const handleChangeCpf = e => {
    const { name, value } = e.target

    if (value.length >= 15) {
      setMask('99.999.999/9999-99')
    } else {
      setMask('999.999.999-999')
    }

    setForm({
      ...form,
      [name]: value,
    })
  }

  const handleValidCpfCnpj = e => {
    const { name, value } = e.target

    if (value.length == 14) {
      if (isCpfCnpjValid(value)) {
        setCpfCnpj({
          ...cpfCnpj,
          error: false,
          text: '',
        })
      } else {
        setCpfCnpj({
          ...cpfCnpj,
          error: true,
          text: 'CPF inválido',
        })
      }
    } else if (value.length == 18) {
      if (isCpfCnpjValid(value)) {
        setCpfCnpj({
          ...cpfCnpj,
          error: false,
          text: '',
        })
      } else {
        setCpfCnpj({
          ...cpfCnpj,
          error: true,
          text: 'CNPJ inválido',
        })
      }
    } else {
      setCpfCnpj({
        ...cpfCnpj,
        error: false,
        text: 'Digite um CPF ou CNPJ válido',
      })
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()

    if (cpfCnpj?.error) {
      return dispatch({
        type: SNACKBAR.HARDFAIL,
        error: {
          message: 'CNPJ Inválido',
        },
      })
    }

    try {
      const resp = await axios.post(
        `${config.API_URL}/attendance/nfse/${id}`,
        form
      )
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (id) {
      fetchAttedance()
    }
  }, [id])

  useEffect(() => {
    if (attendance) {
      handleInitDescription()
    }
  }, [attendance])

  return (
    <BaseFilterModal
      open
      handleOpen={handleClose}
      title="Nota Fiscal Eletrônica - NFSe"
      hiddenHelpButton
    >
      <Grid container item xs={12} direction="row" style={{ width: '400px' }}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                style={{ fontSize: '16px', fontWeight: 'normal' }}
                color={colors.primary}
              >
                Utilize os campos abaixo para gerar
                <br /> uma nota fiscal do serviço
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <ReactInputMask
                      mask={mask}
                      maskChar=""
                      name="cpf_cnpj"
                      value={form?.cpf_cnpj}
                      onChange={e => handleChangeCpf(e)}
                      onBlur={e => handleValidCpfCnpj(e)}
                      required
                    >
                      {inputProps => (
                        <TextField
                          {...inputProps}
                          label="CPF/CNPJ"
                          fullWidth
                          variant="outlined"
                          error={cpfCnpj?.error}
                          helperText={cpfCnpj?.text}
                        />
                      )}
                    </ReactInputMask>
                  </Grid>
                  <Grid item xs={12}>
                    <InputBase
                      name="description"
                      value={form.description}
                      onChange={handleChange}
                      placeholder="Descrição"
                      multiline
                      required
                      inputProps={{
                        style: {
                          minHeight: '169px',
                          maxHeight: '200px',
                          overflow: 'auto',
                        },
                      }}
                      fullWidth
                      style={{
                        borderRadius: '10px',
                        border: `1px solid ${colors.graylight}`,
                        minHeight: '169px',
                        padding: '8px 0 8px 8px',
                        maxHeight: '200px',
                      }}
                    />
                  </Grid>

                  <Grid item xs={6} container justifyContent="flex-start">
                    <TextField
                      label="Valor da Nota:"
                      required
                      value={form.value}
                      variant="outlined"
                      onChange={e =>
                        setForm({
                          ...form,
                          value: e.target.value,
                        })
                      }
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                        inputProps: {
                          prefix: 'R$ ',
                          style: {
                            textAlign: 'left',
                            color: colors.textPrimary,
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    container
                    justifyContent="flex-end"
                    alignItems="flex-end"
                  >
                    <IconButton
                      type="submit"
                      color={colors.primary}
                      variant="contained"
                      className={filterClasses.btnAdd}
                    >
                      <Icon path={mdiInvoiceTextCheckOutline} size={1} />
                      Gerar
                    </IconButton>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </BaseFilterModal>
  )
}

export default ModalEletronicInvoice

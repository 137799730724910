/* eslint-disable import/order */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { useDispatch } from 'react-redux'

import colors from '../../../assets/colors'
import ReactInputMask from 'react-input-mask'
import Tab from '../../../components/hooks/BaseTabs/Tab'
import Tabs from '../../../components/hooks/BaseTabs/Tabs'
import { mdiProgressUpload } from '@mdi/js'
import Icon from '@mdi/react'
import { fetchCnpjInfo, store } from '../LaundryEletronicInvoiceActions'
import { SNACKBAR } from '../../main/MainActions'
import { isCpfCnpjValid } from '../../../util/cpf_cnpj_validator'

const useStyles = makeStyles({
  subTitle: {
    padding: '8px 0',
    borderTop: '1px dashed',
    borderColor: colors.textSecondary,
    borderBottom: '1px dashed',
    borderBottomColor: colors.textSecondary,
    color: colors.textPrimary,
    fontSize: '14px',
  },
  company_box: {
    padding: '8px',
    borderRadius: '3px',
    boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.2)',
  },
  details_box: {
    padding: '12px',
    borderRadius: '3px',
    boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.2)',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  btnSave: {
    borderRadius: '20px',
    color: '#FFF',
    backgroundColor: colors.primary,
    fontSize: '12px',
    textTransform: 'capitalize',
    width: '101px',
    padding: '4px',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: colors.primary,
      opacity: '.7',
    },
  },
  uploadLogo: {
    width: '100%',
    height: '100%',
    border: `1px dashed ${colors.primary}`,
    padding: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '4px',
    fontSize: '14px',
    borderRadius: '10px',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: '#FFF',
      opacity: '.6',
    },
  },
  certificateStatus: {
    fontSize: '12px',
    color: colors.graylight,
  },
  cartifacateValidate: {
    backgroundColor: colors.bgSucess,
    borderRadius: '15px',
    color: colors.primary,
    fontSize: '12px',
  },
})

const Form = ({
  haveRegistration = false,
  reloadVerifyRegistratiron = () => { },
  registrationData,
}) => {
  const dispatch = useDispatch()

  const classes = useStyles()
  const inputRef = useRef()

  const [value, setValue] = useState(0)

  const [form, setForm] = useState({
    cnpj: '',
    nome: '',
    nome_fantasia: '',
    inscricao_estadual: '',
    inscricao_municipal: '',
    regime_tributario: '',
    arquivo_certificado_base64: '',
    senha_certificado: '',

    email: '',
    telefone: '',

    cep: '',
    municipio: '',
    numero: '',
    uf: '',
    logradouro: '',
    complemento: '',
    bairro: '',

    nome_responsavel: '',
    cpf_responsavel: '',
    login_responsavel: '',
    senha_responsavel: '',
  })

  const [validatedForm, setValidatedForm] = useState({
    cnpj: '',
    nome: '',
    nome_fantasia: '',
    inscricao_municipal: '',
    regime_tributario: '',
    arquivo_certificado_base64: '',
    senha_certificado: '',

    email: '',
    telefone: '',

    cep: '',
    municipio: '',
    numero: '',
    uf: '',
    logradouro: '',
    bairro: '',

    nome_responsavel: '',
    cpf_responsavel: '',
    login_responsavel: '',
    senha_responsavel: '',
  })

  const handleChange = e => {
    e.preventDefault()

    const { name, value } = e.target

    setForm({
      ...form,
      [name]: value,
    })

    setValidatedForm({
      ...validatedForm,
      [name]: '',
    })
  }

  const handleChooseFile = () => {
    inputRef.current.click()
  }

  const handleChangeCertificate = event => {
    const { files } = event.target

    if (files[0] == null) {
      return setForm({
        ...form,
        arquivo_certificado_base64: '',
      })
    }

    if (files[0]?.type == 'application/pkcs12' || files[0]?.type == 'application/x-pkcs12') {
      setForm({
        ...form,
        arquivo_certificado_base64: files[0],
      })
    } else {
      setForm({
        ...form,
        arquivo_certificado_base64: '',
      })
    }
  }

  const fetchCnpj = async cnpj => {
    try {
      const resp = await fetchCnpjInfo(cnpj)

      if (resp?.data) {
        const { data } = resp

        setForm({
          ...form,
          nome: data?.razao_social,
          cep: data?.endereco?.cep,
          municipio: data?.endereco?.municipio,
          numero: data?.endereco?.numero,
          uf: data?.endereco?.uf,
          logradouro: data?.endereco?.logradouro,
          complemento: data?.endereco?.complemento,
          bairro: data?.endereco?.bairro,
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleValidCnpj = cnpj => {
    const valueHasSize = cnpj.length == 18

    if (valueHasSize) {
      const validCnpj = isCpfCnpjValid(cnpj)

      if (validCnpj) {
        setValidatedForm({
          ...validatedForm,
          cnpj: '',
        })

        fetchCnpj(cnpj)
      } else {
        setValidatedForm({
          ...validatedForm,
          cnpj: 'CNPJ inválido',
        })
      }
    } else {
      setValidatedForm({
        ...validatedForm,
        cnpj: 'CNPJ inválido',
      })
    }
  }

  const handleValidateForm = () => {
    let hasFormError = false

    const formKeys = Object.keys(form)
    const validatedFormKeys = Object.keys(validatedForm)

    const commonKeys = formKeys.filter(key => validatedFormKeys.includes(key))

    const currentForm = { ...validatedForm }

    commonKeys.forEach(key => {
      if (!form[key]) {
        hasFormError = true
        currentForm[key] = 'Preencha o campo'
      } else {
        currentForm[key] = ''
      }
    })

    if (validatedForm.cnpj) {
      handleValidCnpj(form.cnpj)
    }

    setValidatedForm(currentForm)

    return hasFormError
  }

  const handleSubmit = async e => {
    e.preventDefault()

    const hasFormError = handleValidateForm()

    if (hasFormError) {
      return dispatch({
        type: SNACKBAR.HARDFAIL,
        error: { message: 'Complete todos os campos do formulário' },
      })
    }

    const data = new FormData()

    Object.entries(form).forEach(([key, value]) => {
      data.append(key, value)
    })

    try {
      const resp = await store(data)

      dispatch({
        type: SNACKBAR.SOFTSUCCESS,
        success: { message: 'Empresa cadastrada com sucesso' },
      })

      reloadVerifyRegistratiron()
    } catch (e) {
      dispatch({
        type: SNACKBAR.HARDFAIL,
        error: { message: 'Erro ao criar empresa' },
      })
    }
  }

  useEffect(() => {
    setForm({
      ...form,
      ...registrationData,
    })
  }, [registrationData])

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box className={classes.company_box}>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={6} md={3}>
                    <ReactInputMask
                      mask="99.999.999/9999-99"
                      name="cnpj"
                      maskChar=""
                      value={form?.cnpj}
                      required
                      readOnly={haveRegistration}
                      onChange={handleChange}
                      onBlur={e => {
                        if (!haveRegistration) {
                          handleValidCnpj(e.target.value)
                        }
                      }}
                    >
                      {inputProps => (
                        <TextField
                          {...inputProps}
                          label="CNPJ"
                          fullWidth
                          error={validatedForm.cnpj}
                          helperText={validatedForm.cnpj}
                        />
                      )}
                    </ReactInputMask>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      label="Razão Social"
                      name="nome"
                      required
                      disabled={haveRegistration}
                      fullWidth
                      value={form?.nome}
                      onChange={handleChange}
                      error={validatedForm.nome}
                      helperText={validatedForm.nome}
                    />
                  </Grid>

                  {form.arquivo_certificado_base64 && !haveRegistration && (
                    <Grid item xs={12} sm={6} md={3}>
                      <TextField
                        label="Senha do Certificado"
                        name="senha_certificado"
                        required
                        type="password"
                        fullWidth
                        value={form?.senha_certificado}
                        onChange={handleChange}
                        error={validatedForm.senha_certificado}
                        helperText={validatedForm.senha_certificado}
                      />
                    </Grid>
                  )}

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md
                    container
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Grid item xs={form.arquivo_certificado_base64 ? 12 : 4}>
                      <input
                        ref={inputRef}
                        name="logo"
                        type="file"
                        accept=".pfx,.p12"
                        onChange={handleChangeCertificate}
                        style={{ display: 'none' }}
                      />
                      <IconButton
                        onClick={handleChooseFile}
                        className={classes.uploadLogo}
                        disabled={haveRegistration}
                      >
                        <Grid container spacing={1}>
                          <Grid
                            item
                            xs={12}
                            container
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Icon path={mdiProgressUpload} size={1} />
                            {haveRegistration
                              ? 'Certificado'
                              : 'Anexar Certificado'}
                          </Grid>
                          <Grid item xs={12}>
                            {haveRegistration ? (
                              <Typography
                                className={classes.cartifacateValidate}
                              >
                                Válido até {form?.validade_certificado}
                              </Typography>
                            ) : (
                              <Typography className={classes.certificateStatus}>
                                Certificado Digital:
                                {form.arquivo_certificado_base64
                                  ? 'Válido'
                                  : 'Ausente'}
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            {!haveRegistration && (
              <Grid item xs={12}>
                <Box className={classes.details_box}>
                  <Grid container>
                    <Tabs>
                      <Tab
                        label="Identificação"
                        value={value}
                        setValue={setValue}
                        tabIndex={0}
                        removeNavigation={false}
                      />
                      <Tab
                        label="Contato"
                        value={value}
                        setValue={setValue}
                        tabIndex={1}
                        removeNavigation={false}
                      />
                      <Tab
                        label="Endereço"
                        value={value}
                        setValue={setValue}
                        tabIndex={2}
                        removeNavigation={false}
                      />
                      <Tab
                        label="Responsável"
                        value={value}
                        setValue={setValue}
                        tabIndex={3}
                        removeNavigation={false}
                      />
                      <Tab
                        label="Documentos Fiscais"
                        value={value}
                        setValue={setValue}
                        tabIndex={4}
                        removeNavigation={false}
                      />
                    </Tabs>
                  </Grid>

                  {value == 0 && (
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          label="Nome Fantasia"
                          name="nome_fantasia"
                          type="text"
                          fullWidth
                          required
                          value={form.nome_fantasia}
                          onChange={handleChange}
                          error={validatedForm.nome_fantasia}
                          helperText={validatedForm.nome_fantasia}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          label="Inscrição Estadual"
                          name="inscricao_estadual"
                          type="text"
                          fullWidth
                          value={form.inscricao_estadual}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={3}>
                        <ReactInputMask
                          mask="999999/999-9"
                          name="inscricao_municipal"
                          maskChar=""
                          value={form.inscricao_municipal}
                          onChange={handleChange}
                        >
                          {inputProps => (
                            <TextField
                              {...inputProps}
                              fullWidth
                              required
                              label="Inscrição Municipal"
                              error={validatedForm.inscricao_municipal}
                              helperText={validatedForm.inscricao_municipal}
                            />
                          )}
                        </ReactInputMask>
                      </Grid>

                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          label="Regime Tributario"
                          name="regime_tributario"
                          type="text"
                          fullWidth
                          required
                          select
                          value={form.regime_tributario}
                          onChange={handleChange}
                          error={validatedForm.regime_tributario}
                          helperText={validatedForm.regime_tributario}
                        >
                          <MenuItem value="">Selecione...</MenuItem>
                          <MenuItem value="1">Simples Nacional</MenuItem>
                          <MenuItem value="2">
                            Simples Nacional - Excesso de sublime de receita
                            bruta
                          </MenuItem>
                          <MenuItem value="3">Regime Normal</MenuItem>
                        </TextField>
                      </Grid>
                    </Grid>
                  )}

                  {value == 1 && (
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          label="E-mail"
                          name="email"
                          type="email"
                          fullWidth
                          required
                          value={form.email}
                          onChange={handleChange}
                          error={validatedForm.email}
                          helperText={validatedForm.email}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={3}>
                        <ReactInputMask
                          mask="(99) 99999-9999"
                          name="telefone"
                          maskChar=""
                          value={form?.telefone}
                          onChange={handleChange}
                        >
                          {inputProps => (
                            <TextField
                              {...inputProps}
                              fullWidth
                              required
                              label="Telefone"
                              error={validatedForm.telefone}
                              helperText={validatedForm.telefone}
                            />
                          )}
                        </ReactInputMask>
                      </Grid>
                    </Grid>
                  )}

                  {value == 2 && (
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} md={4}>
                        <ReactInputMask
                          mask="99.999-999"
                          name="cep"
                          maskChar=""
                          value={form?.cep}
                          required
                          onChange={handleChange}
                        >
                          {inputProps => (
                            <TextField
                              {...inputProps}
                              label="CEP"
                              fullWidth
                              error={validatedForm.cep}
                              helperText={validatedForm.cep}
                            />
                          )}
                        </ReactInputMask>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          label="Logradouro"
                          name="logradouro"
                          type="text"
                          fullWidth
                          required
                          value={form.logradouro}
                          onChange={handleChange}
                          error={validatedForm.logradouro}
                          helperText={validatedForm.logradouro}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          label="Bairro"
                          name="bairro"
                          type="text"
                          fullWidth
                          required
                          value={form.bairro}
                          onChange={handleChange}
                          error={validatedForm.bairro}
                          helperText={validatedForm.bairro}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          label="Número"
                          name="numero"
                          type="number"
                          fullWidth
                          required
                          value={form.numero}
                          onChange={handleChange}
                          error={validatedForm.numero}
                          helperText={validatedForm.numero}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          label="Complemento"
                          name="complemento"
                          type="text"
                          fullWidth
                          value={form.complemento}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          label="Municipio"
                          name="municipio"
                          type="text"
                          fullWidth
                          value={form.municipio}
                          onChange={handleChange}
                          disabled
                          required
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          label="UF"
                          name="uf"
                          type="text"
                          fullWidth
                          value={form.uf}
                          onChange={handleChange}
                          disabled
                          required
                        />
                      </Grid>
                    </Grid>
                  )}

                  {value == 3 && (
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          label="Nome responsável"
                          name="nome_responsavel"
                          type="text"
                          fullWidth
                          value={form.nome_responsavel}
                          onChange={handleChange}
                          error={validatedForm.nome_responsavel}
                          helperText={validatedForm.nome_responsavel}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={3}>
                        <ReactInputMask
                          mask="999.999.999-99"
                          name="cpf_responsavel"
                          maskChar=""
                          value={form?.cpf_responsavel}
                          required
                          onChange={handleChange}
                        >
                          {inputProps => (
                            <TextField
                              {...inputProps}
                              label="CPF responsável"
                              fullWidth
                              error={validatedForm.cpf_responsavel}
                              helperText={validatedForm.cpf_responsavel}
                            />
                          )}
                        </ReactInputMask>
                      </Grid>
                    </Grid>
                  )}

                  {value == 4 && (
                    <Grid container spacing={3}>
                      <Grid item xs={12} style={{ color: colors.textPrimary }}>
                        <p>
                          Algumas prefeituras utilizam usuário e senha para
                          realizar a emissão de NFSe.
                        </p>
                      </Grid>

                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          label="Login"
                          name="login_responsavel"
                          type="text"
                          fullWidth
                          required
                          value={form.login_responsavel}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={3}>
                        <TextField
                          label="Senha"
                          name="senha_responsavel"
                          type="password"
                          fullWidth
                          value={form.senha_responsavel}
                          onChange={handleChange}
                          required
                        />
                      </Grid>
                    </Grid>
                  )}

                  <Grid container justifyContent="flex-end">
                    <Button
                      className={classes.btnSave}
                      type="submit"
                      onClick={handleValidateForm}
                    >
                      Salvar
                    </Button>
                  </Grid>
                </Box>
              </Grid>
            )}
          </Grid>
        </form>
      </Grid>
    </Grid>
  )
}

export default Form

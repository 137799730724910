/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable react/prop-types */
import { MenuItem, Typography, makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import SearchIcon from '@material-ui/icons/Search'
import React, { useEffect, useState } from 'react'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { useDispatch, useSelector } from 'react-redux'

import BaseFilter from '../../../components/hooks/BaseFilter'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import Icon from '@mdi/react'
import { mdiFilterMultiple, mdiMicrosoftExcel } from '@mdi/js'
import colors from '../../../assets/colors'
import BaseFilterModal from '../../../components/hooks/BaseFilterModal'
import { fetchStockRelatoryInit } from '../StockRelatoryActions'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'

const useStyles = makeStyles({
  modal: {
    width: '180px',
  },
})

const Filter = ({ form, setForm, movementType, handleReport }) => {
  const technicals = useSelector(state => state.stockRelatory.technicals)
  const products = useSelector(state => state.stock.products)

  const [open, setOpen] = useState(false)

  const classes = useStyles()

  const dispatch = useDispatch()

  const today = () => {
    const date = new Date()

    return format(date, 'yyyy-MM-dd', { locale: ptBR })
  }

  const [currentForm, setCurrentForm] = useState({
    technical_id: '',
    product_id: '',
    date_start: today(),
    date_end: today(),
  })

  const filterStyles = filterStylesComponents()

  const handleOpen = () => {
    setOpen(!open)
  }

  const handleChange = e => {
    e.preventDefault()

    const { name, value } = e.target

    setCurrentForm({
      ...currentForm,
      [name]: value,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()

    setForm({
      ...form,
      ...currentForm,
    })

    handleOpen()
  }

  useEffect(() => {
    setForm({
      ...form,
      product_id: currentForm?.product_id,
      technical_id: currentForm?.technical_id,
    })
  }, [currentForm?.product_id, currentForm?.technical_id])

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid container xs={8} md={6} spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              onChange={handleChange}
              name="technical_id"
              select
              label="Filtre por Técnico"
              className={classes.inputFilter}
              fullWidth
              value={currentForm.technical_id}
            >
              <MenuItem key="" value="">
                Selecione...
              </MenuItem>
              {technicals?.map(technical => (
                <MenuItem key={technical.id} value={technical.id}>
                  {technical.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              name="product_id"
              onChange={handleChange}
              select
              label="Filtre por produto"
              className={classes.inputFilter}
              fullWidth
              value={currentForm.product_id}
            >
              <MenuItem key="" value="">
                Selecione...
              </MenuItem>
              {products?.map(product => (
                <MenuItem key={product.id} value={product.id}>
                  {product.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>

        <Grid container xs={4} md={6} className={filterStyles.areaButtons}>
          <IconButton
            className={filterStyles.btnMoreFilters}
            onClick={handleOpen}
          >
            <Icon path={mdiFilterMultiple} size={1} color={colors.secondary} />
            Filtros
          </IconButton>

          <IconButton className={filterStyles.btnAdd} onClick={handleReport}>
            <Icon path={mdiMicrosoftExcel} size={1} />
            Donwload
          </IconButton>
        </Grid>
      </Grid>

      <BaseFilterModal title="FILTRO" open={open} handleOpen={handleOpen}>
        <Grid item xs={12}>
          <Typography
            style={{ fontSize: '16px', fontWeight: 'normal' }}
            color={colors.primary}
          >
            Utilize os campos abaixo para encontrar <br /> uma movimentação
          </Typography>
        </Grid>

        <Grid item xs={12} className={classes.modal}>
          <Grid
            container
            className={classes.gridContainer}
            spacing={1}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={6}>
              <TextField
                onChange={handleChange}
                name="technical_id"
                select
                label="Filtre por Técnico"
                className={classes.inputFilter}
                fullWidth
                value={currentForm.technical_id}
              >
                <MenuItem key="" value="">
                  Selecione...
                </MenuItem>
                {technicals?.map(technical => (
                  <MenuItem key={technical.id} value={technical.id}>
                    {technical.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={6}>
              <TextField
                select
                label="Filtre por produto"
                className={classes.inputFilter}
                fullWidth
                value={currentForm.product_id}
                onChange={handleChange}
                name="product_id"
              >
                <MenuItem key="" value="">
                  Selecione...
                </MenuItem>
                {products?.map(product => (
                  <MenuItem key={product.id} value={product.id}>
                    {product.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={6}>
              <TextField
                type="date"
                label="Data de começo"
                className={classes.inputFilter}
                fullWidth
                value={currentForm.date_start}
                onChange={handleChange}
                name="date_start"
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                type="date"
                label="Data de fim"
                className={classes.inputFilter}
                fullWidth
                value={currentForm.date_end}
                onChange={handleChange}
                name="date_end"
              />
            </Grid>

            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
              style={{ marginTop: '1.5em' }}
            >
              <IconButton
                type="submit"
                color="primary"
                fullWidth
                variant="contained"
                className={filterStyles.btnSalvar}
                onClick={handleSubmit}
              >
                <SearchIcon />
                Pesquisar
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </BaseFilterModal>
    </>
  )
}

export default Filter

import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'

import Auth from './Login'
import NewPassword from './NewPassword'
import ValidatePassword from './ValidatePassword'
import { createLaundry } from './newlaundryclient/index'

const OpenRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Auth} />
      <Route path="/password/reset/:token" component={NewPassword} />
      <Route
        path="/api/password/validate/:token"
        component={ValidatePassword}
      />
      <Route exact path="/newlaundryclient" component={createLaundry} />
    </Switch>
  )
}

export default withRouter(OpenRoutes)

/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-duplicates */
/* eslint-disable object-shorthand */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import React, { useEffect, useState } from 'react'
import BaseFilterModal from '../../../components/hooks/BaseFilterModal'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core'
import colors from '../../../assets/colors'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { ptBR } from 'date-fns/locale'
import { format, getDaysInMonth, getMonth, getYear, setMonth } from 'date-fns'
import filterStylesComponents from '../../../assets/filterComponentsStyles'
import { mdiMagnify } from '@mdi/js'
import Icon from '@mdi/react'
import { useDispatch, useSelector } from 'react-redux'
import { SNACKBAR } from '../../main/MainActions'
import {
  enableApp,
  findAppVisualizationPeriod,
  getEnableAppDates,
} from '../CalendarActions'
import { setDate as AddDay } from 'date-fns'

const useStyles = makeStyles({
  container: {
    width: '80px',
  },
  form: {
    maxHeight: 'calc(330px + 50px)',
    overflowY: 'auto',
  },
  calendarDate: {
    margin: 0,
    padding: '8px',
    borderRadius: '50%',
    fontSize: '16px',
    color: colors.graylight,
    minWidth: 0,
    width: '29px',
    height: '30px',
    fontWeight: 'normal',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: colors.secondary,
      color: '#FFF',
    },
  },
  active: {
    backgroundColor: colors.secondary,
    color: '#FFF',
  },
  period: {
    backgroundColor: 'rgba(83, 159, 221, 0.17)',
    color: colors.secondary,
    borderRadius: 0,
  },
  periodDay: {
    color: colors.secondary,
    '&:hover': {
      backgroundColor: 'rgba(83, 159, 221, 0.17)',
      color: colors.secondary,
    },
  },
  containerStart: {
    borderRadius: '50% 0 0 50%',
    backgroundColor: 'rgba(83, 159, 221, 0.17)',
  },
  containerEnd: {
    borderRadius: '0 50% 50% 0',
    backgroundColor: 'rgba(83, 159, 221, 0.17)',
  },
})

const EnableAppModal = () => {
  const history = useHistory()
  const classes = useStyles()
  const dispatch = useDispatch()
  const enableAppDates = useSelector(state => state.calendar.enableAppDates)
  const filterStyles = filterStylesComponents()

  const today = () => {
    const date = new Date()

    return format(date, 'yyyy-MM-dd')
  }

  const [date, setDate] = useState(today())
  const [days, setDays] = useState({
    start: '',
    end: '',
  })
  const [dates, setDates] = useState({
    date_start: today(),
    date_end: today(),
  })

  const [daysInMonth, setDaysInMonth] = useState([])

  const handleOpen = () => {
    history.push('/calendar')
  }

  const dispatchToast = message => {
    return dispatch({
      type: SNACKBAR.HARDFAIL,
      error: {
        message: message,
      },
    })
  }

  // const handleChange = e => {
  //   const datePicker = new Date(e)

  //   setDate(format(datePicker, 'yyyy-MM-dd'))
  // }

  const handleChange = e => {
    e.preventDefault()

    const { name, value } = e.target

    setDates({
      ...dates,
      [name]: value,
    })
    // setDate(format(datePicker, 'yyyy-MM-dd'))
  }

  // const handleSelectDay = day => {
  //   if (!days?.start) {
  //     setDays({
  //       ...days,
  //       start: day,
  //     })
  //   }

  //   if (days?.start && !days?.end) {
  //     setDays({
  //       ...days,
  //       end: day,
  //     })
  //   }

  //   if (days?.start && days?.end) {
  //     setDays({
  //       ...days,
  //       start: day,
  //       end: '',
  //     })
  //   }
  // }

  // const getCompleteCalendarDate = (date, day) => {
  //   const currentDate = new Date(date)
  //   const finalDate = AddDay(currentDate, day)

  //   return format(finalDate, 'yyyy-MM-dd')
  // }

  const handleSubmit = async e => {
    e.preventDefault()

    if (!dates?.date_start || !dates?.date_end) {
      return dispatchToast('Selecione o período corretamente')
    }

    // const params = {
    //   date_start: getCompleteCalendarDate(date, days?.start),
    //   date_end: getCompleteCalendarDate(date, days?.end),
    // }

    const resp = await dispatch(enableApp(dates))

    if (resp) {
      handleOpen()
    }
  }

  // useEffect(() => {
  //   setDaysInMonth([])

  //   const pickedDate = new Date(date)
  //   const daysInMonth = getDaysInMonth(pickedDate)

  //   const numbers = []
  //   for (let i = 1; i <= daysInMonth; i++) {
  //     numbers.push(i)
  //   }

  //   setDaysInMonth(numbers)
  // }, [date])

  useEffect(() => {
    if (enableAppDates) {
      // if (enableAppDates?.start) {
      //   const dateEnd = new Date(enableAppDates?.end)

      //   setDate(dateEnd)
      // }

      setDates({
        ...dates,
        date_end: enableAppDates?.end,
        date_start: enableAppDates?.start,
      })
      // setDays({
      //   ...days,
      //   start: enableAppDates?.day_start,
      //   end: enableAppDates?.day_end,
      // })
    }
  }, [enableAppDates])

  useEffect(() => {
    dispatch(findAppVisualizationPeriod())
  }, [])

  return (
    <BaseFilterModal
      open
      handleOpen={handleOpen}
      title="Habilitar visualização no APP"
    >
      <Grid item xs={12} className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              style={{ fontSize: '16px', fontWeight: 'normal' }}
              color={colors.primary}
            >
              Selecione um período para habilitar a <br />
              visualização no aplicativo
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <TextField
                  type="date"
                  label="Data Inicial"
                  fullWidth
                  defaultValue={today()}
                  name="date_start"
                  value={dates.date_start}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <TextField
                  type="date"
                  label="Data Final"
                  defaultValue={today()}
                  fullWidth
                  name="date_end"
                  value={dates.date_end}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid item xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
              <DatePicker
                views={['year', 'month']}
                openTo="month"
                value={date}
                onChange={handleChange}
                format="MMMM yyyy"
                autoOk
              />
            </MuiPickersUtilsProvider>
          </Grid> */}

          {/* <Grid item xs={12}>
            <Grid container alignItems="center">
              {daysInMonth?.map(day => {
                return (
                  <>
                    render nine numbers
                    <Grid
                      item
                      xs={1}
                      className={[
                        day == days?.start && days?.end
                          ? classes.containerStart
                          : '',
                        day == days?.end ? classes.containerEnd : '',
                        days?.start < day && day < days?.end
                          ? classes.period
                          : '',
                      ]}
                    >
                      <Button
                        className={[
                          classes.calendarDate,
                          days?.start == day || days?.end == day
                            ? classes.active
                            : '',
                          days?.start < day && day < days?.end
                            ? classes.periodDay
                            : '',
                        ]}
                        onClick={() => {
                          handleSelectDay(day)
                        }}
                      >
                        {day}
                      </Button>
                    </Grid>
                    {day % 9 == 0 && <Grid item xs={3} />}
                  </>
                )
              })}
            </Grid>
          </Grid> */}

          <Grid item xs={12}>
            <Grid container alignItems="center" justifyContent="center">
              <Grid item>
                <IconButton
                  className={filterStyles.btnAdd}
                  onClick={handleSubmit}
                >
                  <Icon path={mdiMagnify} size={1} />
                  Aplicar
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </BaseFilterModal>
  )
}

export default EnableAppModal

/* eslint-disable eqeqeq */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-return-assign */
/* eslint-disable object-shorthand */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/order */
/* eslint-disable no-unused-vars */
/* eslint-disable spaced-comment */
/* eslint-disable radix */
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import DeleteIcon from '@material-ui/icons/Delete'
import PencilIcon from '@material-ui/icons/Edit'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { useConfirm } from 'material-ui-confirm'
import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

import { getSorting, stableSort } from '../../../util/TableUtils'
import { renderRoute, renderRouteRole } from '../../../util/utils'
import Paginate from '../../components/Pagination'
import EnhancedTableHead from '../../components/TableHead'
import consts from '../../../util/consts'

import DownloadOrcamento from './DownloadOrcamento'
import DownloadVisita from './DownloadVisita'
import Icon from '@mdi/react'
import {
  mdiCash,
  mdiCashSync,
  mdiDelete,
  mdiDotsVertical,
  mdiFilePdfBox,
  mdiFileWordBoxOutline,
  mdiNoteEditOutline,
  mdiReceiptTextOutline,
} from '@mdi/js'
import {
  Chip,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Popper,
} from '@material-ui/core'
import colors from '../../../assets/colors'
import fontSizes from '../../../assets/fontSizes'
import statusColors from '../../../assets/statusColors'
import { downloadOrcamento } from '../AttendanceActions'

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#FFFF',
    color: colors.textPrimary,
  },
  body: {
    fontSize: 14,
    padding: '0 12px',
    color: colors.textPrimary,
  },
}))(TableCell)

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(5),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableStickHeader: {
    maxHeight: 'calc(100vh - 252px)',
  },
  paper: {
    marginRight: 0,
    borderRadius: '10px',
    color: colors.textPrimary,
    fontSize: fontSizes.text,
    boxShadow: '0px 0px 7px 0px #00000040',
  },
  buttons: {
    color: colors.primary,
  },
  ref: {
    width: '34px',
    height: '34px',
    position: 'absolute',
    backgroundColor: '#FFFF',
    transform: 'rotate(45deg)',
    right: '7px',
    '&:hover': {
      backgroundColor: '#FFFF',
    },
  },
  menuList: {
    padding: '10px',
  },
  menuItem: {
    padding: '0 2px',
    height: 'auto',
    lineHeight: '1',
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: 'inherit',
      color: colors.primary,
    },
  },
  menuItemLi: {
    padding: 0,
    margin: 0,
    borderRadius: 0,
    transition: 'all .4s ease-in-out',
    '&:hover': {
      backgroundColor: 'inherit',
      color: colors.primary,
    },
    fontSize: '14px',
  },
  menuItemLiIcon: {
    marginRight: '12px',
  },
}))

function List(props) {
  const {
    permissions,
    remove,
    headCells,
    rows,
    handleChangeRowsPerPage,
    onMovePage,
    rowsPerPage,
    page,
    totalSize,
    otherIcons,
    otherIconsClick,
  } = props
  const classes = useStyles()
  const confirm = useConfirm()
  const dispatch = useDispatch()

  const role = useSelector(state => state.auth.role)

  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('id')

  const [open, setOpen] = useState({
    id: '',
  })

  const anchorRefs = useRef([])

  const handleToggle = id => {
    if (open?.id === id) {
      return setOpen({
        id: '',
      })
    }

    setOpen({
      ...open,
      id: id,
    })
  }

  const handleClose = (event, index) => {
    if (
      anchorRefs.current[index] &&
      anchorRefs.current[index].contains(event.target)
    ) {
      return
    }

    setOpen({
      id: '',
    })
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen({
        id: '',
      })
    }
  }

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc'
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(property)
  }

  const confirmDelete = id => {
    confirm({
      description: 'Após ser apagado o conteúdo não poderá ser recuperado?',
      title: 'Tem certeza?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar',
      dialogProps: {
        fullWidth: true,
      },
    }).then(() => {
      dispatch(remove(id))
    })
  }

  const confirmFinance = (link, status) => {
    if (status !== 4) {
      confirm({
        description:
          'O serviço ainda não está finalizado, deseja gerar o pagamento?',
        title: 'Tem certeza?',
        confirmationText: 'Sim',
        cancellationText: 'Cancelar',
        dialogProps: {
          fullWidth: true,
        },
      }).then(() => {
        props.history.push(link)
      })
    } else {
      props.history.push(link)
    }
  }

  const confirmDownloadOrcamento = async (id, type) => {
    confirm({
      description: 'Deseja baixar este orçamento?',
      title: 'Tem certeza?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar',
      dialogProps: {
        fullWidth: true,
      },
    }).then(() => {
      dispatch(downloadOrcamento(id, type))
    })
  }

  return (
    <>
      <TableContainer className={classes.tableStickHeader}>
        <Table
          className={classes.table}
          aria-labelledby="Attendance table"
          size="medium"
          stickyHeader
        >
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            headCells={headCells}
            hideID
            renderFirstTH={false}
          />
          <TableBody>
            {stableSort(rows, getSorting(order, orderBy)).map(row => {
              return (
                <TableRow hover tabIndex={-1} key={row.id}>
                  {/* <TableCell padding="checkbox" /> */}
                  {Object.entries(row).map((item, index) => {
                    const except = ['update', 'delete', 'link_update']
                    if (
                      item[0] === headCells[index]?.id &&
                      except.indexOf(item[0]) === -1
                    ) {
                      if (item[0] === 'id') {
                        return ''
                      }

                      if (item[0] === 'status') {
                        const status = statusColors?.filter(
                          status => status?.status === item[1]
                        )

                        return (
                          <StyledTableCell key={item[0]} align="center">
                            {/* use o item[1] - valor do status para selecionar a cor */}
                            <Chip
                              size="small"
                              label={item[1]}
                              style={{
                                backgroundColor: status[0]?.color,
                                borderRadius: '8px',
                                fontSize: '14px',
                                color: '#FFFF',
                              }}
                            />
                          </StyledTableCell>
                        )
                      }
                      return (
                        <StyledTableCell key={item[0]}>
                          {item[1]}
                        </StyledTableCell>
                      )
                    }
                  })}

                  <StyledTableCell>
                    <IconButton
                      ref={el => (anchorRefs.current[row?.id] = el)}
                      key={row.id}
                      id={row?.id}
                      aria-controls={
                        open?.id ? `menu-list-grow-${row.id}` : undefined
                      }
                      aria-haspopup="true"
                      onClick={() => {
                        handleToggle(row.id)
                      }}
                    >
                      <Icon path={mdiDotsVertical} size={1} />
                    </IconButton>

                    <Popper
                      open={open?.id === row?.id}
                      role={undefined}
                      anchorEl={anchorRefs.current[row?.id]}
                      transition
                      disablePortal
                      style={{
                        zIndex: 99999999,
                      }}
                      placement="bottom-end"
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement == 'bottom' ? 'left-end' : 'top-end',
                          }}
                        >
                          <Paper className={classes.paper}>
                            <ClickAwayListener onClickAway={handleClose}>
                              <MenuList
                                autoFocusItem={open?.id}
                                id={`menu-list-grow-${row.id}`}
                                onKeyDown={handleListKeyDown}
                                className={classes.menuList}
                              >
                                <MenuItem
                                  className={classes.ref}
                                  style={
                                    placement === 'bottom-end'
                                      ? { top: '-2px' }
                                      : { bottom: '-2px' }
                                  }
                                />
                                {otherIcons?.map((item, index) => {
                                  return (
                                    <MenuItem
                                      onClick={handleClose}
                                      className={classes.menuItem}
                                    >
                                      <IconButton
                                        key={String(index)}
                                        aria-label={item.ariaLabel}
                                        component={Link}
                                        to={item.link(row.id)}
                                        className={classes.menuItemLi}
                                      >
                                        <Icon
                                          path={item.icon}
                                          size={1}
                                          className={classes.menuItemLiIcon}
                                        />
                                        Agenda
                                      </IconButton>
                                    </MenuItem>
                                  )
                                })}
                                {renderRoute([row.delete], permissions) &&
                                  row.statusId != consts.CANCELADO && (
                                    <MenuItem
                                      onClick={handleClose}
                                      className={classes.menuItem}
                                    >
                                      <IconButton
                                        onClick={() => confirmDelete(row.id)}
                                        className={classes.menuItemLi}
                                      >
                                        <Icon
                                          path={mdiDelete}
                                          size={1}
                                          className={classes.menuItemLiIcon}
                                        />
                                        Deletar
                                      </IconButton>
                                    </MenuItem>
                                  )}

                                {renderRoute([row.update], permissions) && (
                                  <MenuItem
                                    onClick={handleClose}
                                    className={classes.menuItem}
                                  >
                                    <IconButton
                                      aria-label="edit"
                                      component={Link}
                                      to={row.link_update}
                                      className={classes.menuItemLi}
                                    >
                                      <Icon
                                        path={mdiNoteEditOutline}
                                        size={1}
                                        className={classes.menuItemLiIcon}
                                      />
                                      Editar
                                    </IconButton>
                                  </MenuItem>
                                )}

                                <MenuItem
                                  onClick={handleClose}
                                  className={classes.menuItem}
                                >
                                  <IconButton
                                    onClick={() => {
                                      confirmDownloadOrcamento(row.id, 'docx')
                                    }}
                                    className={classes.menuItemLi}
                                  >
                                    <Icon
                                      path={mdiFileWordBoxOutline}
                                      size={1}
                                      className={classes.menuItemLiIcon}
                                    />
                                    Exportar Word
                                  </IconButton>
                                </MenuItem>

                                <MenuItem
                                  onClick={handleClose}
                                  className={classes.menuItem}
                                >
                                  <IconButton
                                    onClick={() => {
                                      confirmDownloadOrcamento(row.id, 'pdf')
                                    }}
                                    className={classes.menuItemLi}
                                  >
                                    <Icon
                                      path={mdiFilePdfBox}
                                      size={1}
                                      className={classes.menuItemLiIcon}
                                    />
                                    Exportar PDF
                                  </IconButton>
                                </MenuItem>

                                {![
                                  consts.ORCAMENTO,
                                  consts.CANCELADO,
                                  consts.ARQUIVADO,
                                ].includes(row.statusId) &&
                                  (renderRoute([row.finance], permissions) &&
                                  !row.paymentCompleted ? (
                                    <>
                                      {row.hasBilling &&
                                        renderRoute(
                                          [row.finance, 'read-billing'],
                                          permissions
                                        ) && (
                                          <MenuItem
                                            onClick={handleClose}
                                            className={classes.menuItem}
                                          >
                                            <IconButton
                                              aria-label="finance"
                                              component={Link}
                                              to={`/billing?service_id=${
                                                row.id_laundry_client
                                                  ? row.id_laundry_client
                                                  : row.hasBilling
                                              }`}
                                              className={classes.menuItemLi}
                                            >
                                              <Icon
                                                path={mdiCashSync}
                                                size={1}
                                                className={
                                                  classes.menuItemLiIcon
                                                }
                                              />
                                              Visualizar Pagamento
                                            </IconButton>
                                          </MenuItem>
                                        )}

                                      <MenuItem
                                        onClick={handleClose}
                                        className={classes.menuItem}
                                      >
                                        <IconButton
                                          aria-label="finance"
                                          onClick={() =>
                                            confirmFinance(
                                              row.link_finance,
                                              row.statusId
                                            )
                                          }
                                          className={classes.menuItemLi}
                                        >
                                          <Icon
                                            path={mdiCash}
                                            size={1}
                                            className={classes.menuItemLiIcon}
                                          />
                                          Gerar Pagamento
                                        </IconButton>
                                      </MenuItem>
                                    </>
                                  ) : (
                                    renderRoute(
                                      [row.finance, 'read-billing'],
                                      permissions,
                                      {
                                        all: true,
                                      }
                                    ) && (
                                      <MenuItem
                                        onClick={handleClose}
                                        className={classes.menuItem}
                                      >
                                        <IconButton
                                          aria-label="finance"
                                          component={Link}
                                          to={`/billing?service_id=${
                                            row.id_laundry_client
                                              ? row.id_laundry_client
                                              : row.hasBilling
                                          }`}
                                          className={classes.menuItemLi}
                                        >
                                          <Icon
                                            path={mdiCashSync}
                                            size={1}
                                            className={classes.menuItemLiIcon}
                                          />
                                          Visualizar Pagamento
                                        </IconButton>
                                      </MenuItem>
                                    )
                                  ))}

                                <MenuItem
                                  onClick={handleClose}
                                  className={classes.menuItem}
                                >
                                  <DownloadVisita
                                    id={row.id}
                                    serviceId={row.id}
                                    classes={classes}
                                  />
                                </MenuItem>

                                {/* {[consts.CONCLUIDO]?.includes(row.statusId) &&
                                  renderRouteRole(
                                    ['gerente_lavanderia'],
                                    role
                                  ) && (
                                    <MenuItem
                                      onClick={handleClose}
                                      className={classes.menuItem}
                                    >
                                      <IconButton
                                        aria-label="eletronic-invoice"
                                        component={Link}
                                        to={`/attendance/eletronic-invoice/${row.id}`}
                                        className={classes.menuItemLi}
                                      >
                                        <Icon
                                          path={mdiReceiptTextOutline}
                                          size={1}
                                          className={classes.menuItemLiIcon}
                                        />
                                        Emitir Nota
                                      </IconButton>
                                    </MenuItem>
                                  )} */}
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </StyledTableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Paginate
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        rowsPerPage={parseInt(rowsPerPage)}
        rows={totalSize}
        page={page}
        handleChangePage={onMovePage}
      />
    </>
  )
}

export default withRouter(List)
